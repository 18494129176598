import React from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import { Container } from "react-bootstrap";
import Header from "./components/header/Header";
import Footer from "./components/Footer";
import HomeScreen from "./pages/HomeScreen";
import ProductScreen from "./pages/product/ProductScreen";
import Test from "./pages/Test";
import MessageContainer from "./components/MessageContainer";
import CategoryScreen from "./pages/category/CategoryScreen";
import LoginScreen from "./pages/auth/LoginScreen";
import RegisterScreen from "./pages/auth/RegisterScreen";
import ProfileScreen from "./pages/profile/ProfileScreen";
import CartScreen from "./pages/cart/CartScreen";
import ShippingScreen from "./pages/cart/ShippingScreen";
import PaymentScreen from "./pages/cart/PaymentScreen";
import PlaceOrderScreen from "./pages/cart/PlaceOrderScreen";
import OrderScreen from "./pages/order/OrderScreen";
import UserListScreen from "./pages/admin/UserListScreen";
import ProductListScreen from "./pages/admin/ProductListScreen";
import OrderListScreen from "./pages/admin/OrderListScreen";
import ProductEditScreen from "./pages/admin/ProductEditScreen";
import ImageListScreen from "./pages/admin/ImageListScreen";
import ProductCreateScreen from "./pages/admin/ProductCreateScreen";
import CategoryListScreen from "./pages/admin/CategoryListScreen";
import CategoryEditScreen from "./pages/admin/CategoryEditScreen";
import CategoryCreateScreen from "./pages/admin/CategoryCreateScreen";
import FavoritesScreen from "./pages/profile/FavoritesScreen";
import ReviewsScreen from "./pages/profile/ReviewsScreen";
import UserReviewListScreen from "./pages/admin/UserReviewListScreen";
import EditReviewScreen from "./pages/profile/EditReviewScreen";
import ReviewListScreen from "./pages/admin/ReviewListScreen";
import SearchProductsScreen from "./pages/search/SearchProductsScreen";

const App = () => {
    return (
        <Router>
            <div className="d-flex flex-column mh-100">
                <Header />
                <MessageContainer />
                <main className="flex-grow-1">
                    <Container>
                        <Route path="/product/:id" component={ProductScreen} />
                        <Route
                            path="/category/:id"
                            component={CategoryScreen}
                            exact
                        />
                        <Route
                            path="/category/:id/filter/:params?"
                            component={CategoryScreen}
                            exact
                        />
                        <Route
                            path="/search/:searchValue"
                            component={SearchProductsScreen}
                            exact
                        />
                        <Route
                            path="/search/:searchValue/filter/:params?"
                            component={SearchProductsScreen}
                            exact
                        />
                        <Route path="/cart/:id?" component={CartScreen} />
                        <Route path="/login" component={LoginScreen} />
                        <Route path="/register" component={RegisterScreen} />
                        <Route
                            path="/profile"
                            component={ProfileScreen}
                            exact
                        />
                        <Route
                            path="/favorites"
                            component={FavoritesScreen}
                            exact
                        />
                        <Route
                            path="/reviews"
                            component={ReviewsScreen}
                            exact
                        />
                        <Route
                            path="/reviews/:id/edit"
                            component={EditReviewScreen}
                            exact
                        />
                        <Route path="/shipping" component={ShippingScreen} />
                        <Route path="/payment" component={PaymentScreen} />
                        <Route
                            path="/placeorder"
                            component={PlaceOrderScreen}
                        />
                        <Route path="/order/:id" component={OrderScreen} />
                        <Route
                            path="/admin/userlist"
                            component={UserListScreen}
                        />
                        <Route
                            path="/admin/reviewlist"
                            component={ReviewListScreen}
                            exact
                        />
                        <Route
                            path="/admin/reviewlist/user/:id"
                            component={UserReviewListScreen}
                            exact
                        />
                        <Route
                            path="/admin/productlist"
                            component={ProductListScreen}
                            exact
                        />
                        <Route
                            path="/admin/productlist/filter/:params?"
                            component={ProductListScreen}
                            exact
                        />
                        <Route
                            path="/admin/orderlist"
                            component={OrderListScreen}
                            exact
                        />
                        <Route
                            path="/admin/orderlist/user/:id"
                            component={OrderListScreen}
                            exact
                        />
                        <Route
                            path="/admin/product/create"
                            component={ProductCreateScreen}
                            exact
                        />
                        <Route
                            path="/admin/product/:id/edit"
                            component={ProductEditScreen}
                            exact
                        />
                        <Route
                            path="/admin/imagelist"
                            component={ImageListScreen}
                            exact
                        />
                        <Route
                            path="/admin/categorylist"
                            component={CategoryListScreen}
                            exact
                        />
                        <Route
                            path="/admin/category/:id/edit"
                            component={CategoryEditScreen}
                            exact
                        />
                        <Route
                            path="/admin/category/create"
                            component={CategoryCreateScreen}
                            exact
                        />
                        <Route path="/" component={HomeScreen} exact />
                        <Route path="/test" component={Test} />
                    </Container>
                </main>
                <Footer />
            </div>
        </Router>
    );
};

export default App;
