export const USER_REVIEWS_REQUEST = "USER_REVIEWS_REQUEST";
export const USER_REVIEWS_SUCCESS = "USER_REVIEWS_SUCCESS";
export const USER_REVIEWS_FAIL = "USER_REVIEWS_FAIL";

export const PRODUCT_REVIEWS_REQUEST = "PRODUCT_REVIEWS_REQUEST";
export const PRODUCT_REVIEWS_SUCCESS = "PRODUCT_REVIEWS_SUCCESS";
export const PRODUCT_REVIEWS_FAIL = "PRODUCT_REVIEWS_FAIL";

export const REVIEW_LIST_RESET = "REVIEW_LIST_RESET";

export const REVIEW_LIST_REQUEST = "REVIEW_LIST_REQUEST";
export const REVIEW_LIST_SUCCESS = "REVIEW_LIST_SUCCESS";
export const REVIEW_LIST_FAIL = "REVIEW_LIST_FAIL";

export const REVIEW_DETAILS_REQUEST = "REVIEW_DETAILS_REQUEST";
export const REVIEW_DETAILS_SUCCESS = "REVIEW_DETAILS_SUCCESS";
export const REVIEW_DETAILS_FAIL = "REVIEW_DETAILS_FAIL";
export const REVIEW_DETAILS_RESET = "REVIEW_DETAILS_RESET";

export const REVIEW_CREATE_REQUEST = "REVIEW_CREATE_REQUEST";
export const REVIEW_CREATE_SUCCESS = "REVIEW_CREATE_SUCCESS";
export const REVIEW_CREATE_FAIL = "REVIEW_CREATE_FAIL";

export const REVIEW_UPDATE_REQUEST = "REVIEW_UPDATE_REQUEST";
export const REVIEW_UPDATE_SUCCESS = "REVIEW_UPDATE_SUCCESS";
export const REVIEW_UPDATE_FAIL = "REVIEW_UPDATE_FAIL";

export const REVIEW_DELETE_REQUEST = "REVIEW_DELETE_REQUEST";
export const REVIEW_DELETE_SUCCESS = "REVIEW_DELETE_SUCCESS";
export const REVIEW_DELETE_FAIL = "REVIEW_DELETE_FAIL";

export const REVIEW_ACTION_RESET = "REVIEW_ACTION_RESET";
