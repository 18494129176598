import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { UserActionsState, UserListState } from "../../reducers/userReducers";
import { getUserList } from "../../actions/userActions";
import GoBack from "../../components/layout/GoBack";
import Loader from "../../components/Loader";
import { RouteComponentProps } from "react-router";
import AdminOnly from "../../components/admin/AdminOnly";
import UserList from "../../components/user/UserList";
import { RootState } from "../../store";

interface Props extends RouteComponentProps {}

const UserListScreen: React.FC<Props> = () => {
    const dispatch = useDispatch();

    const userList: UserListState = useSelector(
        (state: RootState) => state.userList
    );
    const { loading, error, users } = userList;

    const userActions: UserActionsState = useSelector(
        (state: RootState) => state.userActions
    );
    const { success, loading: loadingAction } = userActions;

    useEffect(() => {
        dispatch(getUserList());
    }, [dispatch, success]);

    return (
        <>
            <AdminOnly />
            <GoBack />
            <h4>Users</h4>
            {loading || loadingAction ? (
                <Loader />
            ) : error ? (
                <h2 className="text-danger">{error}</h2>
            ) : (
                <>{users && <UserList users={users} />}</>
            )}
        </>
    );
};

export default UserListScreen;
