import React from "react";
import ReactQuill from "react-quill";

interface Props {
    description: string;
    setDescription: (description: string) => void;
}

const RichTextEditor: React.FC<Props> = ({ description, setDescription }) => {
    const modules = {
        toolbar: [
            [{ font: [] }],
            [{ header: [1, 2, false] }],
            ["bold", "italic", "underline", "strike", { color: [] }],
            [
                { list: "ordered" },
                { list: "bullet" },
                { indent: "-1" },
                { indent: "+1" },
                { align: [] },
            ],
            ["link", "image"],
            ["clean"],
        ],
    };

    const formats = [
        "header",
        "bold",
        "italic",
        "underline",
        "strike",
        "list",
        "bullet",
        "indent",
        "link",
        "image",
        "color",
        "font",
        "align",
    ];

    return (
        <>
            <ReactQuill
                value={description}
                modules={modules}
                formats={formats}
                onChange={setDescription}
                className="bg-white text-dark rounded"
            />
        </>
    );
};

export default RichTextEditor;
