import React from "react";
import { ListGroup } from "react-bootstrap";
import { Order } from "../../constants/types";

interface Props {
    order: Order;
    email: string | undefined;
    showDeliveryStatus?: boolean;
}

const OrderShipping: React.FC<Props> = ({
    order,
    email,
    showDeliveryStatus = false,
}) => {
    return (
        <ListGroup.Item>
            <h4>Shipping</h4>
            <p>
                <strong>Name: </strong> {order.shippingAddress?.name}
            </p>
            <p>
                <strong>Phone Number: </strong> {order.shippingAddress?.phone}
            </p>
            <p>
                <strong>Email: </strong>
                <a href={`mailto:${email}`}>{email}</a>
            </p>
            <p>
                <strong>Address: </strong>
                {order.shippingAddress?.address}, {order.shippingAddress?.city},{" "}
                {order.shippingAddress?.postalCode},{" "}
                {order.shippingAddress?.country}
            </p>
            {order.shippingAddress?.comment && (
                <p>
                    <strong>Comment: </strong>
                    {order.shippingAddress?.comment}
                </p>
            )}
            {showDeliveryStatus ? (
                order.isSent ? (
                    <h6 className="text-success">Sent on {order.sentAt}</h6>
                ) : (
                    <h6 className="text-warning">Not Sent</h6>
                )
            ) : null}
        </ListGroup.Item>
    );
};

export default OrderShipping;
