import React, { useState, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import ItemsPerPageComponent from "./ItemsPerPageComponent";
import PageController from "./PageController";
import SearchComponent from "./SearchComponent";

interface Props {
    data: any[];
    searchOptions?: { path: string; title: string }[];
    setListItems: (listItems: any[]) => void;
    itemsPerPageOptions?: number[];
}

const FilteredComponent: React.FC<Props> = ({
    data,
    setListItems,
    searchOptions,
    children,
    itemsPerPageOptions = [10, 20, 30, 6, 12, 18, 24, 36],
}) => {
    const [filteredItems, setFilteredItems] = useState(data);
    const [pageItems, setPageItems] = useState<any[]>([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(itemsPerPageOptions[0]);

    useEffect(() => {
        setListItems(pageItems);
        //eslint-disable-next-line
    }, [pageItems, itemsPerPage]);

    return (
        <>
            <Row className="">
                <Col md={4} className="">
                    {searchOptions && (
                        <SearchComponent
                            data={data}
                            searchOptions={searchOptions}
                            setFilteredItems={setFilteredItems}
                        />
                    )}
                </Col>
                <Col
                    sm={6}
                    md={4}
                    className="d-flex justify-content-center justify-content-sm-start justify-content-lg-center my-2 my-md-0"
                >
                    <PageController
                        data={filteredItems}
                        itemsPerPage={itemsPerPage}
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                        setPageItems={setPageItems}
                    />
                </Col>
                <Col sm={6} md={4} className="my-2 my-md-0">
                    <ItemsPerPageComponent
                        itemsPerPage={itemsPerPage}
                        setItemsPerPage={setItemsPerPage}
                        itemsPerPageOptions={itemsPerPageOptions}
                    />
                </Col>
            </Row>
            <hr />
            {children}
            <hr />
            <Row>
                <Col className="d-flex justify-content-center">
                    <PageController
                        data={filteredItems}
                        itemsPerPage={itemsPerPage}
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                        setPageItems={setPageItems}
                    />
                </Col>
            </Row>
        </>
    );
};

export default FilteredComponent;
