import React from "react";
import { Button, Modal, Card } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { removeAllMessages, removeMessage } from "../actions/messageActions";
import { MessageListState } from "../reducers/messageReducers";
import { RootState } from "../store";

const MessageContainer: React.FC = () => {
    const dispatch = useDispatch();
    const messageList: MessageListState = useSelector(
        (state: RootState) => state.messageList
    );
    const { messages } = messageList;
    const handleClose = () => {
        if (messages.length > 0) {
            dispatch(removeAllMessages());
        }
    };
    return (
        <Modal show={messages.length > 0} onHide={handleClose}>
            <Modal.Body>
                {messages.map((message) => (
                    <Card key={message.id} className="mt-2">
                        <Card.Body>
                            <Card.Title className={`text-${message.variant}`}>
                                {message.title !== (null || undefined)
                                    ? message.title.toUpperCase()
                                    : message?.variant?.toUpperCase()}
                            </Card.Title>
                            {message.text}
                        </Card.Body>
                        <Card.Footer>
                            <Button
                                onClick={(e) =>
                                    dispatch(removeMessage(message.id))
                                }
                            >
                                Close
                            </Button>
                        </Card.Footer>
                    </Card>
                ))}
            </Modal.Body>
        </Modal>
    );
};

export default MessageContainer;
