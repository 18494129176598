import React, { useEffect } from "react";
import { Row, Col, ListGroup } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { RouteComponentProps } from "react-router-dom";
import { resetCart } from "../../actions/cartActions";
import { createOrder } from "../../actions/orderActions";
import CheckoutSteps from "../../components/order/CheckoutSteps";
import GoBack from "../../components/layout/GoBack";
import OrderPaymentMethod from "../../components/order/OrderPaymentMethod";
import OrderProductRow from "../../components/order/OrderProductRow";
import OrderShipping from "../../components/order/OrderShipping";
import OrderSummary from "../../components/order/OrderSummary";
import { CartState } from "../../reducers/cartReducers";
import { OrderState } from "../../reducers/orderReducers";
import { UserAuthState } from "../../reducers/userReducers";
import { RootState } from "../../store";

interface Props extends RouteComponentProps {}

const PlaceOrderScreen: React.FC<Props> = ({ history }) => {
    const cart: CartState = useSelector((state: RootState) => state.cart);
    const dispatch = useDispatch();

    const addDecimals = (num: number) => {
        return +(Math.round(num * 100) / 100).toFixed(2);
    };

    // Calculate prices
    cart.itemsPrice = addDecimals(
        cart.cartItems.reduce((acc, item) => acc + item.price! * item.qty!, 0)
    );

    cart.shippingPrice = addDecimals(cart.itemsPrice > 100 ? 0 : 15);

    cart.taxPrice = addDecimals(Number((0.2 * cart.itemsPrice).toFixed(2)));
    cart.totalPrice = +(
        Number(cart.itemsPrice) +
        Number(cart.shippingPrice) +
        Number(cart.taxPrice)
    ).toFixed(2);

    const orderDetails: OrderState = useSelector(
        (state: RootState) => state.orderDetails
    );
    const { order, success, error } = orderDetails;
    const userAuth: UserAuthState = useSelector(
        (state: RootState) => state.userAuth
    );
    const { loading, userInfo } = userAuth!;
    const user = userInfo?.user;

    useEffect(() => {
        if (!loading && !user) {
            history.push(`/login`);
        }
        if (success && order != null) {
            dispatch(resetCart());
            history.push(`/order/${order.id}`);
        }
        // eslint-disable-next-line
    }, [history, success, order]);

    const placeOrderHandler = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        dispatch(
            createOrder({
                orderItems: cart.cartItems,
                shippingAddress: cart.shippingAddress,
                paymentMethod: cart.paymentMethod,
                itemsPrice: cart.itemsPrice,
                shippingPrice: cart.shippingPrice,
                taxPrice: cart.taxPrice,
                totalPrice: cart.totalPrice,
                user: user?.id,
            })
        );
    };

    return (
        <>
            <GoBack />
            <CheckoutSteps step1 step2 step3 step4 />
            {error && <h2 className="text-danger">{error}</h2>}
            <Row>
                <Col md={8}>
                    <ListGroup variant="flush">
                        <OrderShipping order={cart} email={user?.email} />

                        <OrderPaymentMethod order={cart} />

                        <ListGroup.Item>
                            <h4>Order Items</h4>
                            {cart.cartItems?.length === 0 ? (
                                <h6 className="text-warning">
                                    Your cart is empty
                                </h6>
                            ) : (
                                <ListGroup variant="flush">
                                    {cart.cartItems.map((item) => (
                                        <ListGroup.Item key={item.product}>
                                            <OrderProductRow
                                                key={item.product}
                                                item={item}
                                                addDecimals={addDecimals}
                                            />
                                        </ListGroup.Item>
                                    ))}
                                </ListGroup>
                            )}
                        </ListGroup.Item>
                    </ListGroup>
                </Col>
                <Col md={4}>
                    <ListGroup
                        variant="flush"
                        className="text-center mt-md-0 mt-3 "
                    >
                        <OrderSummary
                            order={cart}
                            placeOrderHandler={placeOrderHandler}
                            showPlaceOrderButton={cart.cartItems.length > 0}
                        />
                    </ListGroup>
                </Col>
            </Row>
        </>
    );
};

export default PlaceOrderScreen;
