import React, { Fragment, useEffect } from "react";
import { Pagination } from "react-bootstrap";

interface Props {
    data: any[];
    itemsPerPage: number;
    currentPage: number;
    setCurrentPage: (page: number) => void;
    setPageItems: (listItems: any[]) => void;
}

const PageController: React.FC<Props> = ({
    data,
    itemsPerPage,
    currentPage,
    setCurrentPage,
    setPageItems,
}) => {
    const totalCount = data.length;
    const hasPages = totalCount > itemsPerPage;
    const totalPages = Math.ceil(totalCount / itemsPerPage);
    const goPreviousPage = () => {
        setCurrentPage(currentPage - 1);
    };
    const goNextPage = () => {
        setCurrentPage(currentPage + 1);
    };

    const getPaginationGroup = () => {
        return new Array(totalPages).fill(1).map((_, idx) => idx + 1);
    };

    const PaginationComponent = () => (
        <Pagination className="mb-0">
            <Pagination.Prev
                onClick={goPreviousPage}
                disabled={currentPage === 1}
            />
            <Pagination.Item
                onClick={(e) => setCurrentPage(1)}
                active={currentPage === 1}
            >
                {1}
            </Pagination.Item>
            {currentPage > 1 + 3 && <Pagination.Ellipsis />}
            {getPaginationGroup().map((pageNumber) => (
                <Fragment key={pageNumber}>
                    {pageNumber > 1 &&
                        pageNumber < totalPages &&
                        pageNumber < currentPage + 3 &&
                        pageNumber > currentPage - 3 && (
                            <Pagination.Item
                                onClick={(e) => setCurrentPage(pageNumber)}
                                active={currentPage === pageNumber}
                            >
                                {pageNumber}
                            </Pagination.Item>
                        )}
                </Fragment>
            ))}
            {currentPage < totalPages - 3 && <Pagination.Ellipsis />}
            <Pagination.Item
                onClick={(e) => setCurrentPage(totalPages)}
                active={currentPage === totalPages}
            >
                {totalPages}
            </Pagination.Item>
            <Pagination.Next
                onClick={goNextPage}
                disabled={currentPage === totalPages}
            />
        </Pagination>
    );

    useEffect(() => {
        setCurrentPage(1);
        //eslint-disable-next-line
    }, [data, itemsPerPage]);

    useEffect(() => {
        setPageItems(
            data.slice(
                itemsPerPage * (currentPage - 1),
                itemsPerPage * currentPage
            )
        );
        //eslint-disable-next-line
    }, [currentPage, data, itemsPerPage]);
    return <>{hasPages && <PaginationComponent />}</>;
};

export default PageController;
