import React, {
    // useEffect,
    useState,
} from "react";
import { Form, ListGroup } from "react-bootstrap";
// import { useDispatch, useSelector } from "react-redux";
// import { changeFilters } from "../../actions/filteringActions";
import { SpecificationFields } from "../../constants/types";
// import { CategoryListState } from "../../reducers/categoryReducers";
// import { filteringState } from "../../reducers/filteringReducers";
// import { RootState } from "../../store";

interface Props {
    field: SpecificationFields;
    // index: number;
}

const ProductFilteringSpecificationField: React.FC<Props> = ({
    field,
    // index,
}) => {
    const [currentValue, setCurrentValue] = useState("");
    // const dispatch = useDispatch();
    // const [fieldState, setFieldState] =
    //     useState<{ name: string; value: string; index: number } | null>(null);
    // const filtering: filteringState = useSelector(
    //     (state: RootState) => state.filtering
    // );
    // const { filters } = filtering;

    // useEffect(() => {
    //     filters.specification.map((mappedField) => {
    //         if (
    //             mappedField.split('"="')[0].toLowerCase() ===
    //             field.fieldName.toLowerCase()
    //         ) {
    //             return setFieldState({
    //                 name: mappedField.split('"="')[0],
    //                 value: mappedField.split('"="')[1],
    //                 index,
    //             });
    //         } else {
    //             return null;
    //         }
    //     });
    //     //eslint-disable-next-line
    // }, [filters]);

    // const clickHandler = (value: string) => {
    //     let newSpecification = filters.specification;
    //     newSpecification[
    //         fieldState?.index || index
    //     ] = `${field?.fieldName}"="${value}`;
    //     dispatch(
    //         changeFilters({
    //             ...filters,
    //             specification: newSpecification,
    //         })
    //     );
    // };

    return (
        <Form.Group>
            <ListGroup.Item key={field.fieldName}>
                <Form.Label>{field.fieldName}</Form.Label>
                {field.fieldOptions.split(",").map((value) => (
                    <Form.Check
                        key={value}
                        type="radio"
                        label={value}
                        checked={currentValue === value}
                        // onChange={() => clickHandler(value)}
                        onChange={() => setCurrentValue(value)}
                        id={`specification-select-${value}`}
                    />
                ))}
            </ListGroup.Item>
        </Form.Group>
    );
};

export default ProductFilteringSpecificationField;
