import React from "react";
import { ButtonGroup, Button } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { User } from "../../constants/types";

interface Props {
    user: User;
    blockHandler: (id: number) => void;
    unblockHandler: (id: number) => void;
    userInfo: { jwt: string; user: User };
}

const UserListItem: React.FC<Props> = ({
    user,
    blockHandler,
    unblockHandler,
    userInfo,
}) => {
    return (
        <tr key={user.id} className={`${user.blocked && "text-danger"}`}>
            <td>{user.id}</td>
            <td>{user.name}</td>
            <td>{user.username}</td>
            <td>
                <a href={`mailto:${user.email}`}>{user.email}</a>
            </td>
            <td>
                {user.blocked ? (
                    <span className="text-danger">BLOCKED</span>
                ) : user.role?.type === "admin" ? (
                    <span className="text-primary">ADMIN</span>
                ) : (
                    <span className="text-success">USER</span>
                )}
            </td>
            <td>
                {user.orders && user.orders.length > 0 ? (
                    <ButtonGroup>
                        <Button variant="dark" disabled>
                            {user.orders.length}
                        </Button>
                        <LinkContainer to={`/admin/orderlist/user/${user.id}`}>
                            <Button variant="info" className="btn-sm">
                                <i className="fas fa-list"></i>
                                <span className="d-block">VIEW</span>
                            </Button>
                        </LinkContainer>
                    </ButtonGroup>
                ) : (
                    <span className="text-secondary">No Orders</span>
                )}
            </td>
            <td>
                {user.reviews && user.reviews.length > 0 ? (
                    <ButtonGroup>
                        <Button variant="dark" disabled>
                            {user.reviews.length}
                        </Button>
                        <LinkContainer to={`/admin/reviewlist/user/${user.id}`}>
                            <Button variant="info" className="btn-sm">
                                <i className="fas fa-list"></i>
                                <span className="d-block">VIEW</span>
                            </Button>
                        </LinkContainer>
                    </ButtonGroup>
                ) : (
                    <span className="text-secondary">No Reviews</span>
                )}
            </td>
            <td>
                <ButtonGroup>
                    {user.blocked ? (
                        <Button
                            variant="warning"
                            className="btn-sm"
                            onClick={(e) => unblockHandler(user.id!)}
                        >
                            <i className="fas fa-unlock" />
                            <span className="d-block">UNBAN</span>
                        </Button>
                    ) : (
                        <Button
                            variant="danger"
                            className="btn-sm"
                            onClick={(e) => blockHandler(user.id!)}
                            disabled={user.id === userInfo?.user.id}
                        >
                            <i className="fas fa-lock" />
                            <span className="d-block">BLOCK</span>
                        </Button>
                    )}
                </ButtonGroup>
            </td>
        </tr>
    );
};

export default UserListItem;
