import React from "react";
import DOMPurify from "dompurify";
import { Container, Tab, Table, Tabs } from "react-bootstrap";
import { Product } from "../../constants/types";
import ProductReviews from "./ProductReviews";

interface Props {
    product: Product;
}

const ProductTabs: React.FC<Props> = ({ product }) => {
    return (
        <Tabs
            fill
            defaultActiveKey="description"
            id="product-description"
            className="my-4 tab-parent"
        >
            <Tab eventKey="description" title="Description" className="tab">
                <Container>
                    {product.description !== null ? (
                        <div
                            dangerouslySetInnerHTML={{
                                __html: DOMPurify.sanitize(
                                    product.description || ""
                                ),
                            }}
                            className="ql-editor"
                        />
                    ) : (
                        <h6 className="text-center">No description listed</h6>
                    )}
                </Container>
            </Tab>
            <Tab eventKey="specification" title="Specification" className="tab">
                <Container className="text-center">
                    {product.specification != null ? (
                        <Table bordered hover className="tabler-vcenter">
                            <tbody>
                                {Object.keys(product.specification).map(
                                    (field) => (
                                        <tr key={field}>
                                            <td>{field}</td>
                                            <td>
                                                {product?.specification?.hasOwnProperty(
                                                    field
                                                ) &&
                                                    product.specification[
                                                        field
                                                    ]}
                                            </td>
                                        </tr>
                                    )
                                )}
                            </tbody>
                        </Table>
                    ) : (
                        <h6>No specification listed</h6>
                    )}
                </Container>
            </Tab>
            <Tab eventKey="reviews" title="Reviews" className="mr-md-2 tab">
                <Container>
                    <ProductReviews product={product} />
                </Container>
            </Tab>
        </Tabs>
    );
};

export default ProductTabs;
