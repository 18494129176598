import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col } from "react-bootstrap";
import {
    getProductDetails,
    resetGetProductDetails,
} from "../../actions/productActions";
import GoBack from "../../components/layout/GoBack";
import Loader from "../../components/Loader";
import ProductImages from "../../components/images/ProductImages";
import CategoryTrail from "../../components/category/CategoryTrail";
import ProductActionBlock from "../../components/product/ProductActionBlock";
import ProductTabs from "../../components/product/ProductTabs";
import { Link, match, RouteComponentProps } from "react-router-dom";
import { ProductDetailsState } from "../../reducers/productReducers";
import { UserAuthState } from "../../reducers/userReducers";
import { RootState } from "../../store";

interface Props extends RouteComponentProps {
    match: match<{ id: string }>;
}

const ProductScreen: React.FC<Props> = ({ history, match }) => {
    const dispatch = useDispatch();
    const productDetails: ProductDetailsState = useSelector(
        (state: RootState) => state.productDetails
    );
    const { loading, error, product } = productDetails;
    const productId = match.params.id;

    const userAuth: UserAuthState = useSelector(
        (state: RootState) => state.userAuth
    );
    const { userInfo } = userAuth;

    useEffect(() => {
        dispatch(resetGetProductDetails());
        dispatch(getProductDetails(productId));
    }, [dispatch, productId]);

    return (
        <>
            <GoBack />
            {userInfo?.user?.role?.type === "admin" && (
                <Link to={`/admin/product/${productId}/edit`}>
                    <span className="btn btn-secondary my-3 mx-2">Edit</span>
                </Link>
            )}
            {loading || loading === undefined ? (
                <Loader />
            ) : error ? (
                <>
                    <h3>Unable to load product</h3>
                </>
            ) : (
                <>
                    {typeof product?.category === "object" && (
                        <CategoryTrail categoryId={product.category?.id} />
                    )}
                    <h4>{product?.name}</h4>
                    <small className="text-secondary">
                        Product ID: {product?.id}
                    </small>
                    {product?.code && (
                        <small className="text-secondary">
                            {"  "}| Product Code: {product.code}
                        </small>
                    )}
                    <hr />
                    <Row>
                        <Col lg={7} md={8}>
                            <ProductImages
                                title={product?.name}
                                images={product?.images}
                            />
                        </Col>
                        <Col lg={5} md={4}>
                            {product && (
                                <ProductActionBlock
                                    history={history}
                                    product={product}
                                />
                            )}
                        </Col>
                    </Row>
                    {product && <ProductTabs product={product} />}
                </>
            )}
        </>
    );
};

export default ProductScreen;
