import React from "react";
import { NavDropdown } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";

interface Props {
    location: { pathname: string };
}

const AdminPanelDropdown: React.FC<Props> = ({ location }) => {
    return (
        <NavDropdown title="Admin Panel" id="adminmenu">
            <LinkContainer to="/admin/userlist">
                <NavDropdown.Item
                    active={location.pathname === "/admin/userlist"}
                >
                    Users
                </NavDropdown.Item>
            </LinkContainer>
            <LinkContainer to="/admin/productlist">
                <NavDropdown.Item
                    active={location.pathname === "/admin/productlist"}
                >
                    Products
                </NavDropdown.Item>
            </LinkContainer>
            <LinkContainer to="/admin/reviewlist">
                <NavDropdown.Item
                    active={location.pathname === "/admin/reviewlist"}
                >
                    Reviews
                </NavDropdown.Item>
            </LinkContainer>
            <LinkContainer to="/admin/orderlist">
                <NavDropdown.Item
                    active={location.pathname === "/admin/orderlist"}
                >
                    Orders
                </NavDropdown.Item>
            </LinkContainer>
            <LinkContainer to="/admin/imagelist">
                <NavDropdown.Item
                    active={location.pathname === "/admin/imagelist"}
                >
                    Images
                </NavDropdown.Item>
            </LinkContainer>
            <LinkContainer to="/admin/categorylist">
                <NavDropdown.Item
                    active={location.pathname === "/admin/categorylist"}
                >
                    Categories
                </NavDropdown.Item>
            </LinkContainer>
        </NavDropdown>
    );
};

export default AdminPanelDropdown;
