import React, { useState, useEffect } from "react";
import { Card, Col, Form, ListGroup, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { changeFilters } from "../../actions/filteringActions";
import { defaultFilters } from "../../constants/filteringConstants";
import { CategoryListState } from "../../reducers/categoryReducers";
import { FilteringState } from "../../reducers/filteringReducers";
import { RootState } from "../../store";

interface Props {}

const ProductFilteringPriceRange: React.FC<Props> = () => {
    const dispatch = useDispatch();
    const filtering: FilteringState = useSelector(
        (state: RootState) => state.filtering
    );
    const { filters } = filtering;
    const categoryList: CategoryListState = useSelector(
        (state: RootState) => state.categoryList
    );
    const { categories } = categoryList;
    const [from, setFrom] = useState<number | string>(filters?.price_gte || "");
    const [to, setTo] = useState<number | string>(filters?.price_lte || "");
    const [stockOnly, toggleStockOnly] = useState(
        filters?.stockOnly || defaultFilters.stockOnly
    );

    useEffect(() => {
        if (
            filters.price_gte !== from ||
            filters.price_lte !== to ||
            filters.stockOnly !== stockOnly
        ) {
            setTimeout(() => {
                setFrom(filters?.price_gte);
                setTo(filters?.price_lte);
                toggleStockOnly(filters?.stockOnly);
            }, 1);
        }

        //eslint-disable-next-line
    }, [categories, filters]);

    useEffect(() => {
        dispatch(
            changeFilters({
                ...filters,
                price_gte: from,
                price_lte: to,
                stockOnly,
            })
        );
        // eslint-disable-next-line
    }, [from, to, stockOnly]);
    return (
        <>
            <Card.Title className="text-primary">Price Range</Card.Title>
            <ListGroup variant="flush">
                <Form.Group>
                    <Row className="d-flex align-items-center">
                        <Col>
                            <Form.Control
                                type="number"
                                placeholder="From"
                                value={from || ""}
                                min={0}
                                max={to > 0 ? to : undefined}
                                onChange={(e) => setFrom(+e.target.value)}
                                className="p-1"
                            />
                        </Col>
                        <span className="text-info">-</span>
                        <Col>
                            <Form.Control
                                type="number"
                                placeholder="To"
                                value={to || ""}
                                min={from || 0}
                                onChange={(e) => setTo(+e.target.value)}
                                className="p-1"
                            />
                        </Col>
                    </Row>
                </Form.Group>
                <Form.Group>
                    <Form.Check
                        type="switch"
                        id={`hide-not-in-stock-switch`}
                        onChange={() => toggleStockOnly(!stockOnly)}
                        checked={stockOnly || false}
                        label="Hide out of stock"
                        className={`${stockOnly && "text-primary"}`}
                    />
                </Form.Group>
            </ListGroup>
        </>
    );
};

export default ProductFilteringPriceRange;
