import {
    PRODUCT_LIST_FAIL,
    PRODUCT_LIST_REQUEST,
    PRODUCT_LIST_SUCCESS,
    PRODUCT_DETAILS_REQUEST,
    PRODUCT_DETAILS_FAIL,
    PRODUCT_DETAILS_RESET,
    PRODUCT_DETAILS_SUCCESS,
    PRODUCT_LIST_IN_CATEGORY_REQUEST,
    PRODUCT_LIST_IN_CATEGORY_SUCCESS,
    PRODUCT_LIST_IN_CATEGORY_FAIL,
    PRODUCT_LIST_IN_CATEGORY_RESET,
    PRODUCT_EDIT_REQUEST,
    PRODUCT_EDIT_SUCCESS,
    PRODUCT_EDIT_FAIL,
    PRODUCT_DELETE_REQUEST,
    PRODUCT_DELETE_SUCCESS,
    PRODUCT_DELETE_FAIL,
    PRODUCT_ACTIONS_RESET,
    PRODUCT_CREATE_REQUEST,
    PRODUCT_CREATE_SUCCESS,
    PRODUCT_CREATE_FAIL,
    PRODUCT_FILTERED_CATEGORY_REQUEST,
    PRODUCT_FILTERED_CATEGORY_SUCCESS,
    PRODUCT_FILTERED_CATEGORY_FAIL,
    PRODUCT_SEARCH_REQUEST,
    PRODUCT_SEARCH_SUCCESS,
    PRODUCT_SEARCH_FAIL,
    PRODUCT_COUNT_REQUEST,
    PRODUCT_COUNT_SUCCESS,
    PRODUCT_COUNT_FAIL,
    PRODUCT_COUNT_RESET,
} from "../constants/productConstants";
import { GeneralStateIF, GenericAction, Product } from "../constants/types";

const productActionsInitialState = {};

export interface ProductActionsState extends GeneralStateIF {
    actionType?: string;
}

export const productActionsReducer = (
    state: ProductActionsState = productActionsInitialState,
    action: GenericAction
): ProductActionsState => {
    switch (action.type) {
        case PRODUCT_CREATE_REQUEST:
            return { loading: true, actionType: PRODUCT_CREATE_REQUEST };
        case PRODUCT_EDIT_REQUEST:
            return { loading: true, actionType: PRODUCT_EDIT_REQUEST };
        case PRODUCT_DELETE_REQUEST:
            return { loading: true, actionType: PRODUCT_DELETE_REQUEST };
        case PRODUCT_CREATE_SUCCESS:
        case PRODUCT_EDIT_SUCCESS:
        case PRODUCT_DELETE_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case PRODUCT_CREATE_FAIL:
        case PRODUCT_EDIT_FAIL:
        case PRODUCT_DELETE_FAIL:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case PRODUCT_ACTIONS_RESET:
            return productActionsInitialState;
        default:
            return state;
    }
};

const productListInitialState = {
    products: [],
    count: 0,
};

export interface ProductListState extends GeneralStateIF {
    products?: Product[];
    count?: number;
}

export const productListReducer = (
    state: ProductListState = productListInitialState,
    action: GenericAction
): ProductListState => {
    switch (action.type) {
        case PRODUCT_LIST_REQUEST:
        case PRODUCT_LIST_IN_CATEGORY_REQUEST:
        case PRODUCT_FILTERED_CATEGORY_REQUEST:
        case PRODUCT_SEARCH_REQUEST:
        case PRODUCT_COUNT_REQUEST:
            return { ...state, loading: true };
        case PRODUCT_LIST_SUCCESS:
        case PRODUCT_LIST_IN_CATEGORY_SUCCESS:
        case PRODUCT_SEARCH_SUCCESS:
            return {
                ...state,
                loading: false,
                products: action.payload,
            };
        case PRODUCT_FILTERED_CATEGORY_SUCCESS:
            return {
                ...state,
                loading: false,
                products: action.payload,
            };
        case PRODUCT_COUNT_SUCCESS:
            return {
                ...state,
                count: action.payload,
            };
        case PRODUCT_LIST_FAIL:
        case PRODUCT_LIST_IN_CATEGORY_FAIL:
        case PRODUCT_FILTERED_CATEGORY_FAIL:
        case PRODUCT_SEARCH_FAIL:
        case PRODUCT_COUNT_FAIL:
            return { loading: false, error: action.payload };
        case PRODUCT_COUNT_RESET:
            return { ...state, count: 0 };
        case PRODUCT_LIST_IN_CATEGORY_RESET:
            return productListInitialState;
        default:
            return state;
    }
};

const productDetailsInitialState = {
    product: { reviews: [], images: [] },
};

export interface ProductDetailsState extends GeneralStateIF {
    product?: Product;
}

export const productDetailsReducer = (
    state: ProductDetailsState = productDetailsInitialState,
    action: GenericAction
): ProductDetailsState => {
    switch (action.type) {
        case PRODUCT_DETAILS_REQUEST:
        case PRODUCT_CREATE_REQUEST:
            return { loading: true };
        case PRODUCT_DETAILS_SUCCESS:
        case PRODUCT_CREATE_SUCCESS:
        case PRODUCT_EDIT_SUCCESS:
            return {
                loading: false,
                product: action.payload,
            };
        case PRODUCT_DETAILS_FAIL:
        case PRODUCT_CREATE_FAIL:
            return { loading: false, error: action.payload };
        case PRODUCT_DETAILS_RESET:
        case PRODUCT_DELETE_SUCCESS:
            return productDetailsInitialState;
        default:
            return state;
    }
};
