import React, { useState, useEffect, FormEvent } from "react";
import { Button, ButtonGroup, Col, Form, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { match, RouteComponentProps } from "react-router";
import {
    deleteCategory,
    getCategoryDetails,
    resetCategoryAction,
    resetCategoryDetails,
    updateCategory,
} from "../../actions/categoryActions";
import { addMessage } from "../../actions/messageActions";
import AdminOnly from "../../components/admin/AdminOnly";
import CategoryEditImage from "../../components/category/CategoryEditImage";
import CategoryFieldForm from "../../components/category/CategoryFieldForm";
import CategorySpecificationFields from "../../components/category/CategorySpecificationFields";
import GoBack from "../../components/layout/GoBack";
import Loader from "../../components/Loader";
import {
    CATEGORY_DELETE_REQUEST,
    CATEGORY_UPDATE_REQUEST,
} from "../../constants/categoryContants";
import { Image } from "../../constants/types";
import {
    CategoryActionState,
    CategoryDetailsState,
    CategoryListState,
} from "../../reducers/categoryReducers";
import { RootState } from "../../store";

interface Props extends RouteComponentProps {
    match: match<{ id: string }>;
}

const CategoryEditScreen: React.FC<Props> = ({ match, history }) => {
    const categoryId = match.params.id;
    const dispatch = useDispatch();
    // TODO Image Upload Bug
    const categoryDetails: CategoryDetailsState = useSelector(
        (state: RootState) => state.categoryDetails
    );
    const { loading, error, category } = categoryDetails;
    const categoryAction: CategoryActionState = useSelector(
        (state: RootState) => state.categoryAction
    );
    const {
        success,
        loading: actionLoading,
        error: actionError,
        actionType,
    } = categoryAction;
    const categoryList: CategoryListState = useSelector(
        (state: RootState) => state.categoryList
    );
    const { categories } = categoryList;

    const [name, setName] = useState("");
    const [parent, setParent] = useState<number | null>(null);
    const [image, setImage] = useState<Image | null>(null);

    useEffect(() => {
        dispatch(resetCategoryDetails());
        dispatch(getCategoryDetails(categoryId));
        dispatch(resetCategoryAction);
    }, [dispatch, categoryId]);

    useEffect(() => {
        if (category) {
            setName(category.name || "");
            setParent(category.parent?.id || null);
            setImage(category.image || null);
        }
        if (success && actionType === CATEGORY_UPDATE_REQUEST) {
            dispatch(
                addMessage({
                    text: "Category Updated",
                    variant: "success",
                })
            );
            dispatch(resetCategoryAction());
        }
        if (success && actionType === CATEGORY_DELETE_REQUEST) {
            dispatch(
                addMessage({
                    text: "Category Deleted",
                    variant: "success",
                })
            );
            history.push("/admin/categorylist");
            dispatch(resetCategoryAction());
        }

        // eslint-disable-next-line
    }, [dispatch, category, error, actionError, success, actionType]);

    const submitHandler = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        dispatch(
            updateCategory(categoryId, {
                name,
                parent: parent !== 0 ? parent : null,
                image,
            })
        );
    };

    const deleteHandler = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
        e.preventDefault();
        if (window.confirm("Are you sure?")) {
            dispatch(deleteCategory(categoryId));
        }
    };

    return (
        <>
            <AdminOnly />
            <GoBack link="/admin/categorylist" />
            <h4>Edit Category</h4>
            {loading || actionLoading ? (
                <Loader />
            ) : error ? (
                <h2 className="text-danger">{error}</h2>
            ) : (
                <Form onSubmit={submitHandler}>
                    <Row>
                        <Col md={8}>
                            {categories && (
                                <CategoryFieldForm
                                    name={name}
                                    setName={setName}
                                    categories={categories}
                                    parent={parent}
                                    categoryId={+categoryId}
                                    setParent={setParent}
                                />
                            )}
                        </Col>
                        <Col md={4}>
                            <CategoryEditImage
                                image={image}
                                setImage={setImage}
                            />
                        </Col>
                    </Row>
                    <ButtonGroup className="mt-3 mt-md-0">
                        <Button
                            type="submit"
                            variant="primary"
                            onSubmit={submitHandler}
                        >
                            Update
                        </Button>
                        <Button variant="danger" onClick={deleteHandler}>
                            Delete
                        </Button>
                    </ButtonGroup>
                    <hr />
                    <Row>
                        <Col>
                            <CategorySpecificationFields
                                initialModel={
                                    category?.specificationModel || []
                                }
                                categoryId={category?.id!}
                            />
                        </Col>
                    </Row>
                </Form>
            )}
        </>
    );
};

export default CategoryEditScreen;
