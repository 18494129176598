import React from "react";
import { Row } from "react-bootstrap";

interface Props {}

const ContentHeader: React.FC<Props> = ({ children }) => {
    return (
        <Row className="d-flex align-items-center mb-2 mb-md-3">{children}</Row>
    );
};

export default ContentHeader;
