import React, { useState } from "react";

import { LinkContainer } from "react-router-bootstrap";
import { Form, FormControl, Nav } from "react-bootstrap";
import { useHistory } from "react-router";

interface Props {}

const SearchBar: React.FC<Props> = () => {
    const [searchValue, setSearchValue] = useState("");
    const history = useHistory();

    const submitHandler = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        history.push(searchValue !== "" ? `/search/${searchValue}` : `/`);
    };
    return (
        <Form
            inline
            className="mr-0 mr-md-5 my-2 my-md-0 d-flex align-items-center justify-content-center"
            onSubmit={(e) => submitHandler(e)}
            style={{ minWidth: "300px" }}
        >
            <FormControl
                type="text"
                placeholder="Search"
                className="mr-2 text-white bg-secondary border-dark"
                style={{
                    maxWidth: "90%",
                    minWidth: "250px",
                }}
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
            />
            <LinkContainer
                to={searchValue !== "" ? `/search/${searchValue}` : `/`}
            >
                <Nav.Link>
                    <i className="fas fa-search ml-2" />
                </Nav.Link>
            </LinkContainer>
        </Form>
    );
};

export default SearchBar;
