export const PRODUCT_LIST_REQUEST = "PRODUCT_LIST_REQUEST";
export const PRODUCT_LIST_SUCCESS = "PRODUCT_LIST_SUCCESS";
export const PRODUCT_LIST_FAIL = "PRODUCT_LIST_FAIL";

export const PRODUCT_DETAILS_REQUEST = "PRODUCT_DETAILS_REQUEST";
export const PRODUCT_DETAILS_SUCCESS = "PRODUCT_DETAILS_SUCCESS";
export const PRODUCT_DETAILS_FAIL = "PRODUCT_DETAILS_FAIL";
export const PRODUCT_DETAILS_RESET = "PRODUCT_DETAILS_RESET";

export const PRODUCT_LIST_IN_CATEGORY_REQUEST =
    "PRODUCT_LIST_IN_CATEGORY_REQUEST";
export const PRODUCT_LIST_IN_CATEGORY_SUCCESS =
    "PRODUCT_LIST_IN_CATEGORY_SUCCESS";
export const PRODUCT_LIST_IN_CATEGORY_FAIL = "PRODUCT_LIST_IN_CATEGORY_FAIL";

export const PRODUCT_SEARCH_REQUEST = "PRODUCT_SEARCH_REQUEST";
export const PRODUCT_SEARCH_SUCCESS = "PRODUCT_SEARCH_SUCCESS";
export const PRODUCT_SEARCH_FAIL = "PRODUCT_SEARCH_FAIL";

export const PRODUCT_FILTERED_CATEGORY_REQUEST =
    "PRODUCT_FILTERED_CATEGORY_REQUEST";
export const PRODUCT_FILTERED_CATEGORY_SUCCESS =
    "PRODUCT_FILTERED_CATEGORY_SUCCESS";
export const PRODUCT_FILTERED_CATEGORY_FAIL = "PRODUCT_FILTERED_CATEGORY_FAIL";

export const PRODUCT_LIST_IN_CATEGORY_RESET = "PRODUCT_LIST_IN_CATEGORY_RESET";

export const PRODUCT_ACTIONS_RESET = "PRODUCT_ACTIONS_RESET";

export const PRODUCT_CREATE_REQUEST = "PRODUCT_CREATE_REQUEST";
export const PRODUCT_CREATE_SUCCESS = "PRODUCT_CREATE_SUCCESS";
export const PRODUCT_CREATE_FAIL = "PRODUCT_CREATE_FAIL";

export const PRODUCT_EDIT_REQUEST = "PRODUCT_EDIT_REQUEST";
export const PRODUCT_EDIT_SUCCESS = "PRODUCT_EDIT_SUCCESS";
export const PRODUCT_EDIT_FAIL = "PRODUCT_EDIT_FAIL";

export const PRODUCT_DELETE_REQUEST = "PRODUCT_DELETE_REQUEST";
export const PRODUCT_DELETE_SUCCESS = "PRODUCT_DELETE_SUCCESS";
export const PRODUCT_DELETE_FAIL = "PRODUCT_DELETE_FAIL";

export const PRODUCT_COUNT_REQUEST = "PRODUCT_COUNT_REQUEST";
export const PRODUCT_COUNT_SUCCESS = "PRODUCT_COUNT_SUCCESS";
export const PRODUCT_COUNT_FAIL = "PRODUCT_COUNT_FAIL";
export const PRODUCT_COUNT_RESET = "PRODUCT_COUNT_RESET";
