export const ADD_MESSAGE = "ADD_MESSAGE";
export const REMOVE_MESSAGE = "REMOVE_MESSAGE";
export const REMOVE_ALL_MESSAGES = "REMOVE_ALL_MESSAGES";

export type MessageType = {
    id: string;
    text: string;
    title?: string;
    variant?: string;
};
