import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getReviewList } from "../../actions/reviewActions";
import AdminOnly from "../../components/admin/AdminOnly";
import GoBack from "../../components/layout/GoBack";
import Loader from "../../components/Loader";
import ReviewList from "../../components/review/ReviewList";
import {
    ReviewActionsState,
    ReviewListState,
} from "../../reducers/reviewReducers";
import { RootState } from "../../store";
interface Props {}

const ReviewListScreen: React.FC<Props> = () => {
    const dispatch = useDispatch();
    const reviewList: ReviewListState = useSelector(
        (state: RootState) => state.reviewList
    );
    const { loading, reviews } = reviewList;

    const reviewActions: ReviewActionsState = useSelector(
        (state: RootState) => state.reviewActions
    );
    const { loading: actionLoading, success } = reviewActions;

    useEffect(() => {
        dispatch(getReviewList());
        //eslint-disable-next-line
    }, [success]);

    return (
        <>
            <GoBack />
            <AdminOnly />
            <h4>Reviews</h4>
            {loading || actionLoading ? (
                <Loader />
            ) : (
                <ReviewList
                    reviews={reviews || []}
                    loading={loading || false}
                    header="both"
                />
            )}
        </>
    );
};

export default ReviewListScreen;
