import React, { useState, Fragment } from "react";
import { ListGroup } from "react-bootstrap";
import { Review } from "../../constants/types";
import FilteredComponent from "../layout/FilteredComponent";
import ReviewListItem from "./ReviewListItem";

interface Props {
    reviews: Review[];
    loading: boolean;
    header?: "user" | "product" | "both";
}

const ReviewList: React.FC<Props> = ({
    reviews,
    loading,
    header = "product",
}) => {
    const [listItems, setListItems] = useState(reviews);

    return (
        <FilteredComponent data={reviews} setListItems={setListItems}>
        <ListGroup variant="flush">
            {listItems?.length > 0 ? (
                reviews.map((review, index) => (
                    <Fragment key={review.id}>
                        <ReviewListItem
                            review={review}
                            loading={loading}
                            notLastItem={index < reviews.length - 1}
                            header={header}
                        />
                    </Fragment>
                ))
            ) : (
                <h5 className="text-info">No Reviews</h5>
            )}
        </ListGroup>
        </FilteredComponent>
    );
};

export default ReviewList;
