import {
    CHANGE_FILTERS,
    defaultFilters,
    LOAD_URL_PARAMS,
    RESET_FILTERS,
} from "../constants/filteringConstants";

const filteringInitialState = { filters: defaultFilters };

export interface FilteringState {
    filters: {
        _sort: string;
        _limit: number;
        stockOnly: boolean;
        price_gte: string;
        price_lte: string;
        category: string;
        // specification: string[];
    };
}

export const filteringReducer = (
    state: FilteringState = filteringInitialState,
    action: any
): FilteringState => {
    switch (action.type) {
        case CHANGE_FILTERS:
            return {
                ...state,
                filters: action.payload,
            };
        case LOAD_URL_PARAMS:
            return {
                ...state,
                filters: action.payload,
            };
        case RESET_FILTERS:
            return filteringInitialState;
        default:
            return state;
    }
};
