import React from "react";

interface Props {
    text: string;
}

const ContentTitle: React.FC<Props> = ({ text }) => {
    return <h4>{text}</h4>;
};

export default ContentTitle;
