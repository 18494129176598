import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Button, ButtonGroup, Col, Form, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
    createCategory,
    resetCategoryAction,
} from "../../actions/categoryActions";
import { addMessage } from "../../actions/messageActions";
import AdminOnly from "../../components/admin/AdminOnly";
import CategoryFieldForm from "../../components/category/CategoryFieldForm";
import Loader from "../../components/Loader";
import { CATEGORY_CREATE_REQUEST } from "../../constants/categoryContants";
import {
    CategoryActionState,
    CategoryListState,
} from "../../reducers/categoryReducers";
import { RootState } from "../../store";

const CategoryCreate = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const categoryAction: CategoryActionState = useSelector(
        (state: RootState) => state.categoryAction
    );
    const {
        success,
        loading: actionLoading,
        error: actionError,
        actionType,
    } = categoryAction;
    const categoryList: CategoryListState = useSelector(
        (state: RootState) => state.categoryList
    );
    const { categories } = categoryList;

    const [name, setName] = useState("");
    const [parent, setParent] = useState<number | null>(null);

    useEffect(() => {
        if (success && actionType === CATEGORY_CREATE_REQUEST) {
            dispatch(
                addMessage({
                    text: "Category Created",
                    variant: "success",
                })
            );
            history.push("/admin/categorylist");
            dispatch(resetCategoryAction());
        }

        // eslint-disable-next-line
    }, [dispatch, actionError, success, actionType]);

    const submitHandler = (e: any) => {
        e.preventDefault();
        dispatch(
            createCategory({
                name,
                parent: parent !== 0 ? parent : null,
            })
        );
    };

    return (
        <>
            <AdminOnly />
            {actionLoading ? (
                <Loader />
            ) : actionError ? (
                <h2 className="text-danger">{actionError}</h2>
            ) : (
                <Form onSubmit={submitHandler}>
                    <Row>
                        <Col>
                            {categories && (
                                <CategoryFieldForm
                                    name={name}
                                    setName={setName}
                                    categories={categories}
                                    parent={parent}
                                    setParent={setParent}
                                />
                            )}
                        </Col>
                    </Row>
                    <ButtonGroup>
                        <Button
                            type="submit"
                            variant="primary"
                            onSubmit={submitHandler}
                        >
                            Create
                        </Button>
                    </ButtonGroup>
                </Form>
            )}
        </>
    );
};

export default CategoryCreate;
