import React from "react";

interface Props {
    text: string;
    variant?:
        | "danger"
        | "warning"
        | "info"
        | "primary"
        | "secondary"
        | "success";
}

const Announcer: React.FC<Props> = ({ text, variant }) => {
    return (
        <h5 className={`${variant ? "text-" + variant : ""} mt-3 ml-3`}>
            {text}
        </h5>
    );
};

export default Announcer;
