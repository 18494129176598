import {
    ADD_MESSAGE,
    MessageType,
    REMOVE_ALL_MESSAGES,
    REMOVE_MESSAGE,
} from "../constants/messageConstants";

const messageListInitialState = {
    messages: [],
};

export interface MessageListState {
    messages: MessageType[];
}

export const messageListReducer = (
    state: MessageListState = messageListInitialState,
    action: any
): MessageListState => {
    switch (action.type) {
        case ADD_MESSAGE:
            return { messages: [action.payload, ...state.messages] };
        case REMOVE_MESSAGE:
            return {
                messages: state.messages.filter(
                    (message) => message.id !== action.payload
                ),
            };
        case REMOVE_ALL_MESSAGES:
            return {
                messages: [],
            };
        default:
            return state;
    }
};
