import React, { useState, useEffect, ChangeEvent } from "react";
import { Badge, Row, Col, Form, Button, ButtonGroup } from "react-bootstrap";
import { SpecificationFields } from "../../constants/types";
import FormField from "../FormField";

interface Props {
    field: SpecificationFields;
    index: number;
    handleFieldChange: (index: number, field: SpecificationFields) => void;
    handleFieldRemove: (index: number) => void;
}

const CategorySpecificationFieldRow: React.FC<Props> = ({
    field,
    index,
    handleFieldChange,
    handleFieldRemove,
}) => {
    const [fieldName, setFieldName] = useState(field.fieldName);
    const [fieldType, setFieldType] = useState(field.fieldType);
    const [fieldOptions, setFieldOptions] = useState(field.fieldOptions);
    const [searchable, toggleSearchable] = useState(field.searchable);

    useEffect(() => {
        setFieldName(field.fieldName);
        setFieldType(field.fieldType);
        setFieldOptions(field.fieldOptions);
        toggleSearchable(field.searchable);
    }, [field]);

    useEffect(() => {
        handleFieldChange(index, {
            fieldName,
            fieldType,
            fieldOptions,
            searchable,
        });
        // eslint-disable-next-line
    }, [fieldName, fieldType, fieldOptions, searchable]);

    return (
        <Form.Group>
            <hr />
            <FormField
                horizontal
                label="Name"
                value={fieldName}
                controlId={fieldName}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    setFieldName(e.target.value)
                }
            />
            <Form.Group as={Row}>
                <Form.Label column sm={2}>
                    Type
                </Form.Label>
                <Col sm={10}>
                    <Form.Control
                        as="select"
                        custom
                        value={fieldType}
                        onChange={(e: ChangeEvent<HTMLInputElement>) =>
                            setFieldType(e.target.value)
                        }
                    >
                        <option value="text">Text</option>
                        <option value="number">Number</option>
                        <option value="option">Option</option>
                    </Form.Control>
                </Col>
            </Form.Group>
            {fieldType === "option" && (
                <>
                    <FormField
                        horizontal
                        label="Options"
                        value={fieldOptions}
                        controlId="options"
                        onChange={(e: ChangeEvent<HTMLInputElement>) =>
                            setFieldOptions(e.target.value)
                        }
                    />
                    {fieldOptions?.split(",").map((el) => (
                        <Badge pill variant="light" className="mx-1" key={el}>
                            {el}
                        </Badge>
                    ))}
                </>
            )}
            <Form.Check
                type="switch"
                id={`${index}-switch`}
                checked={searchable}
                onChange={(e) => toggleSearchable(!searchable)}
                label={`${searchable ? "Filtered" : "Not Filtered"}`}
                className="mt-2"
            />
            <ButtonGroup className="mt-2">
                <Button
                    variant="danger"
                    className="btn-sm"
                    onClick={(e) => handleFieldRemove(index)}
                >
                    <i className="fas fa-times"></i>
                    <span className="d-block">Remove</span>
                </Button>
            </ButtonGroup>
        </Form.Group>
    );
};

export default CategorySpecificationFieldRow;
