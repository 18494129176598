import React from "react";
import { ListGroup } from "react-bootstrap";
import { Order } from "../../constants/types";

interface Props {
    order: Order;
    showPaymentStatus?: boolean;
}

const OrderPaymentMethod: React.FC<Props> = ({
    order,
    showPaymentStatus = false,
}) => {
    return (
        <ListGroup.Item>
            <h4>Payment Method</h4>
            <p>
                <strong>Method: </strong>
                {order.paymentMethod === "cashOnDelivery"
                    ? "Cash On Delivery"
                    : order.paymentMethod}
            </p>
            {showPaymentStatus && order.paymentMethod !== "cashOnDelivery" ? (
                order.isPaid ? (
                    <>
                        <h6 className="text-success">Paid on {order.paidAt}</h6>
                        {order.paymentResult && (
                            <>
                                {order.paymentResult?.id && (
                                    <div>
                                        ID:{" "}
                                        <span className="text-info">
                                            {order.paymentResult?.id}
                                        </span>
                                    </div>
                                )}
                                {order.paymentResult?.status && (
                                    <div>
                                        Status: {order.paymentResult?.status}
                                    </div>
                                )}
                                {order.paymentResult?.purchase_units &&
                                    order.paymentResult?.purchase_units.map(
                                        (purchaseUnit) => (
                                            <div
                                                key={purchaseUnit.reference_id}
                                            >
                                                Amount:{" "}
                                                {purchaseUnit.amount.value}{" "}
                                                {
                                                    purchaseUnit.amount
                                                        .currency_code
                                                }
                                            </div>
                                        )
                                    )}
                            </>
                        )}
                    </>
                ) : (
                    <h6 className="text-warning">Not Paid</h6>
                )
            ) : null}
        </ListGroup.Item>
    );
};

export default OrderPaymentMethod;
