import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Button, ButtonGroup, Card, Col, Row } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { Category } from "../../constants/types";
import SubcategoryListOrder from "../../components/category/SubcategoryListOrder";
import {
    resetCategoryAction,
    updateCategory,
} from "../../actions/categoryActions";

interface Props {
    category: Category;
}

const CategoryCard: React.FC<Props> = ({ category }) => {
    const dispatch = useDispatch();
    const [hasChanged, toggleHasChanged] = useState(false);
    const [subcategoryList, setSubcategoryList] = useState<Category[] | []>([]);
    const [listOrder, setListOrder] = useState(category.listOrder || 0);

    useEffect(() => {
        setSubcategoryList(category.children || []);
    }, [category]);

    useEffect(() => {
        if (
            JSON.stringify(subcategoryList) !==
                JSON.stringify(category.children) ||
            listOrder !== category.listOrder
        ) {
            toggleHasChanged(true);
        } else {
            toggleHasChanged(false);
        }
    }, [category, subcategoryList, listOrder]);

    const submitHandler = () => {
        // Strapi doesn't support changing order in relational fields yet
        const updatedCategory = {
            ...category,
            listOrder,
            // children: [...subcategoryList],
        };
        dispatch(updateCategory(category.id, updatedCategory));
        dispatch(resetCategoryAction());
    };

    return (
        <>
            <Card.Header className="p-1">
                <Row className="text-center align-items-center mx-0">
                    <Col sm={2} className="d-none d-sm-block">
                        <ButtonGroup className="">
                            <Button
                                variant="outline-info"
                                className="btn-sm"
                                disabled={listOrder <= 0}
                                onClick={(e) => setListOrder(listOrder - 1)}
                            >
                                <i className="fas fa-arrow-up" />
                            </Button>
                            <Button variant="outline-secondary" disabled>
                                {listOrder || 0}
                            </Button>
                            <Button
                                variant="outline-info"
                                className="btn-sm"
                                onClick={(e) => setListOrder(listOrder + 1)}
                            >
                                <i className="fas fa-arrow-down" />
                            </Button>
                        </ButtonGroup>
                    </Col>
                    <Col lg={8} md={7} sm={6} xs={7} className="text-primary">
                        <h6
                            className="mb-0"
                            style={{
                                fontWeight: "bold",
                            }}
                        >
                            {category.name}
                        </h6>
                    </Col>
                    <Col lg={1} md={1} sm={2} xs={3}>
                        {hasChanged && (
                            <Button
                                variant="outline-success"
                                className="btn-sm"
                                onClick={(e) => submitHandler()}
                            >
                                <i className="fas fa-check" />
                                <span className="d-block">Apply</span>
                            </Button>
                        )}
                    </Col>
                    <Col lg={1} sm={2} xs={2}>
                        <LinkContainer
                            to={`/admin/category/${category.id}/edit`}
                        >
                            <Button
                                variant="outline-primary"
                                className="btn-sm"
                            >
                                <i className="fas fa-edit" />
                                <span className="d-block">EDIT</span>
                            </Button>
                        </LinkContainer>
                    </Col>
                </Row>
            </Card.Header>
            {subcategoryList != null && subcategoryList.length > 0 && (
                <SubcategoryListOrder
                    subcategories={subcategoryList}
                    setSubcategoryList={setSubcategoryList}
                />
            )}
        </>
    );
};

export default CategoryCard;
