import React, { useState } from "react";
import {
    Button,
    ButtonGroup,
    Col,
    Form,
    ListGroup,
    ListGroupItem,
    Row,
} from "react-bootstrap";
import { RouteComponentProps } from "react-router-dom";
import { Product } from "../../constants/types";
import Rating from "../review/Rating";
import ProductFavoriteButton from "./ProductFavoriteButton";

interface Props {
    history: RouteComponentProps["history"];
    product: Product;
}

const ProductActionBlock: React.FC<Props> = ({ history, product }) => {
    const [qty, setQty] = useState(1);
    const averageRating =
        product && product?.reviews != null
            ? product.reviews.reduce((r, c) => r + c.rating!, 0) /
              product.reviews.length
            : 0;

    const addToCartHandler = () => {
        history.push(`/cart/${product.id}?qty=${qty}`);
    };
    return (
        <ListGroup variant="flush" className="text-center mt-md-0 mt-3">
            <ListGroupItem>
                <h6>{product.name}</h6>
            </ListGroupItem>
            {product.reviews?.length !== (null || 0) && (
                <ListGroupItem>
                    <Rating
                        value={averageRating}
                        text={`${product?.reviews?.length} reviews`}
                    />
                </ListGroupItem>
            )}
            <ListGroupItem>
                <h4 className="my-0">${product.price}</h4>
            </ListGroupItem>
            <ListGroupItem>
                <Row>
                    <Col>Status:</Col>
                    <Col>
                        {product.stock != null && product.stock > 0
                            ? "In Stock"
                            : "Out Of Stock"}
                    </Col>
                </Row>
                {product.stock != null && product.stock > 0 && (
                    <Row className="mt-2">
                        <Col className="pt-2">Qty:</Col>
                        <Col>
                            <Form.Control
                                as="select"
                                value={qty}
                                onChange={(e) => setQty(+e.target.value)}
                                className="bg-dark text-light"
                            >
                                {[...Array(product.stock).keys()]
                                    .slice(0, 20)
                                    .map((x) => (
                                        <option key={x + 1} value={x + 1}>
                                            {x + 1}
                                        </option>
                                    ))}
                            </Form.Control>
                        </Col>
                    </Row>
                )}
            </ListGroupItem>
            <ListGroupItem>
                <ButtonGroup className="w-100">
                    <Button
                        type="button"
                        disabled={product.stock === 0}
                        onClick={addToCartHandler}
                    >
                        <i className="fas fa-cart-plus" />
                        <br />{" "}
                        {product.stock === 0 ? (
                            <span>Out of Stock</span>
                        ) : (
                            <span>
                                <span className="d-md-none d-lg-inline">
                                    Add to{" "}
                                </span>
                                Cart
                            </span>
                        )}
                    </Button>
                    {product.id && (
                        <ProductFavoriteButton productId={product.id} />
                    )}
                </ButtonGroup>
            </ListGroupItem>
        </ListGroup>
    );
};

export default ProductActionBlock;
