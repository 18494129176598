import React, { Fragment } from "react";
import { Card, ListGroup } from "react-bootstrap";
import { SpecificationFields } from "../../constants/types";
import WIP from "../layout/WIP";
import ProductFilteringSpecificationField from "./ProductFilteringSpecificationField";

interface Props {
    specificationModel: SpecificationFields[];
}

const ProductFilteringSpecification: React.FC<Props> = ({
    specificationModel,
}) => {
    return (
        <>
            <Card.Title className="text-primary">Specification</Card.Title>
            <ListGroup variant="flush">
                <ListGroup.Item>
                    <WIP />
                </ListGroup.Item>
                {specificationModel &&
                    specificationModel.map((field, index) => (
                        <Fragment key={field.id}>
                            {field.searchable && (
                                <ProductFilteringSpecificationField
                                    field={field}
                                    // index={index}
                                    key={field.id}
                                />
                            )}
                        </Fragment>
                    ))}
            </ListGroup>
        </>
    );
};

export default ProductFilteringSpecification;
