import {
    ORDER_CREATE_REQUEST,
    ORDER_CREATE_SUCCESS,
    ORDER_CREATE_FAIL,
    ORDER_DETAILS_REQUEST,
    ORDER_DETAILS_SUCCESS,
    ORDER_DETAILS_FAIL,
    ORDER_PAY_REQUEST,
    ORDER_PAY_SUCCESS,
    ORDER_PAY_FAIL,
    ORDER_LIST_MY_REQUEST,
    ORDER_LIST_MY_SUCCESS,
    ORDER_LIST_MY_FAIL,
    ORDER_LIST_MY_RESET,
    ORDER_DETAILS_RESET,
    ORDER_LIST_REQUEST,
    ORDER_LIST_SUCCESS,
    ORDER_LIST_FAIL,
    ORDER_LIST_RESET,
    ORDER_USER_LIST_REQUEST,
    ORDER_USER_LIST_SUCCESS,
    ORDER_USER_LIST_FAIL,
    ORDER_CANCEL_SUCCESS,
    ORDER_CANCEL_REQUEST,
    ORDER_CANCEL_FAIL,
    ORDER_SENT_SUCCESS,
    ORDER_SENT_REQUEST,
    ORDER_SENT_FAIL,
    ORDER_ACTION_RESET,
} from "../constants/orderConstants";
import { GeneralStateIF, GenericAction, Order } from "../constants/types";

const orderActionsInitialState = {};

export interface OrderActionsState extends GeneralStateIF {
    actionType?: string;
}

export const orderActionsReducer = (
    state: OrderActionsState = orderActionsInitialState,
    action: GenericAction
): OrderActionsState => {
    switch (action.type) {
        case ORDER_CREATE_REQUEST:
            return { loading: true, actionType: ORDER_CREATE_REQUEST };
        case ORDER_SENT_REQUEST:
            return { loading: true, actionType: ORDER_SENT_REQUEST };
        case ORDER_PAY_REQUEST:
            return { loading: true, actionType: ORDER_PAY_REQUEST };
        case ORDER_CANCEL_REQUEST:
            return { loading: true, actionType: ORDER_CANCEL_REQUEST };
        case ORDER_CREATE_SUCCESS:
        case ORDER_PAY_SUCCESS:
        case ORDER_CANCEL_SUCCESS:
        case ORDER_SENT_SUCCESS:
            return { ...state, loading: false, success: true };
        case ORDER_CREATE_FAIL:
        case ORDER_PAY_FAIL:
        case ORDER_CANCEL_FAIL:
        case ORDER_SENT_FAIL:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case ORDER_ACTION_RESET:
            return orderActionsInitialState;
        default:
            return state;
    }
};

const orderInitialState = {
    loading: true,
};

export interface OrderState extends GeneralStateIF {
    order?: Order;
}

export const orderReducer = (
    state: OrderState = orderInitialState,
    action: GenericAction
): OrderState => {
    switch (action.type) {
        case ORDER_CREATE_REQUEST:
        case ORDER_DETAILS_REQUEST:
            return {
                loading: true,
            };
        case ORDER_CREATE_SUCCESS:
            return {
                loading: false,
                success: true,
                order: action.payload,
            };
        case ORDER_DETAILS_SUCCESS:
        case ORDER_PAY_SUCCESS:
        case ORDER_CANCEL_SUCCESS:
        case ORDER_SENT_SUCCESS:
            return {
                loading: false,
                order: action.payload,
            };
        case ORDER_CREATE_FAIL:
        case ORDER_DETAILS_FAIL:
            return {
                loading: false,
                error: action.payload,
            };
        case ORDER_DETAILS_RESET:
            return orderInitialState;
        default:
            return state;
    }
};

const orderListMyInitialState = {
    orders: [],
};

export interface OrderListMyState extends GeneralStateIF {
    orders?: Order[];
}

export const orderListMyReducer = (
    state: OrderListMyState = orderListMyInitialState,
    action: GenericAction
): OrderListMyState => {
    switch (action.type) {
        case ORDER_LIST_MY_REQUEST:
            return {
                loading: true,
            };
        case ORDER_LIST_MY_SUCCESS:
            return {
                orders: action.payload,
            };
        case ORDER_LIST_MY_FAIL:
            return {
                loading: false,
                error: action.payload,
            };
        case ORDER_LIST_MY_RESET:
            return orderListMyInitialState;
        default:
            return state;
    }
};

const orderListInitialState = {
    orders: [],
};

export interface OrderListState extends GeneralStateIF {
    orders?: Order[];
}

export const orderListReducer = (
    state: OrderListState = orderListInitialState,
    action: GenericAction
): OrderListState => {
    switch (action.type) {
        case ORDER_LIST_REQUEST:
        case ORDER_USER_LIST_REQUEST:
            return {
                loading: true,
            };
        case ORDER_LIST_SUCCESS:
        case ORDER_USER_LIST_SUCCESS:
            return {
                orders: action.payload,
            };
        case ORDER_LIST_FAIL:
        case ORDER_USER_LIST_FAIL:
            return {
                loading: false,
                error: action.payload,
            };
        case ORDER_LIST_RESET:
            return orderListInitialState;
        default:
            return state;
    }
};
