import React from "react";
import { Form, Row, Col } from "react-bootstrap";

interface Props {
    horizontal?: boolean;
    controlId: string;
    label: string;
    type?: string | undefined;
    placeholder?: string | undefined;
    value: any;
    onChange: any;
}

const FormField: React.FC<Props> = ({
    horizontal = false,
    controlId,
    label,
    type = "text",
    placeholder = `Enter ${label}`,
    value,
    onChange,
}) => {
    return (
        <>
            {horizontal ? (
                <Form.Group as={Row} controlId={controlId}>
                    <Form.Label column sm={2}>
                        {label}
                    </Form.Label>
                    <Col sm={10}>
                        <Form.Control
                            type={type}
                            placeholder={placeholder}
                            value={value}
                            onChange={onChange}
                        ></Form.Control>
                    </Col>
                </Form.Group>
            ) : (
                <Form.Group controlId={controlId}>
                    <Form.Label>{label}</Form.Label>
                    <Form.Control
                        type={type}
                        placeholder={placeholder}
                        value={value}
                        onChange={onChange}
                    ></Form.Control>
                </Form.Group>
            )}
        </>
    );
};

export default FormField;
