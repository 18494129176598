import React, { Fragment, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Row } from "react-bootstrap";
import GoBack from "../../components/layout/GoBack";
import Loader from "../../components/Loader";
import {
    UserActionsState,
    UserDetailsState,
} from "../../reducers/userReducers";
import { getFavorites } from "../../actions/userActions";
import { CategoryListState } from "../../reducers/categoryReducers";
import FavoritesList from "../../components/favorites/FavoritesList";
import { RootState } from "../../store";

interface Props {}

const FavoritesScreen: React.FC<Props> = () => {
    const dispatch = useDispatch();

    const userDetails: UserDetailsState = useSelector(
        (state: RootState) => state.userDetails
    );

    let favorites = userDetails?.user?.favorites || [];

    const categoryList: CategoryListState = useSelector(
        (state: RootState) => state.categoryList
    );
    const { categories } = categoryList;

    const userActions: UserActionsState = useSelector(
        (state: RootState) => state.userActions
    );
    const { loading: loadingAction, error: errorAction } = userActions;

    useEffect(() => {
        dispatch(getFavorites());
    }, [dispatch]);

    return (
        <>
            <GoBack />
            <Row className="align-items-center">
                <Col>
                    <h4>Favorite Products</h4>
                </Col>
            </Row>
            {loadingAction ? (
                <Loader />
            ) : errorAction ? (
                <h2 className="text-danger">{errorAction}</h2>
            ) : (
                <>
                    {favorites && favorites.length > 0 ? (
                        <FavoritesList
                            favorites={favorites}
                            categories={categories || []}
                        />
                    ) : (
                        <h5 className="text-info">
                            You don't have favorite products.
                        </h5>
                    )}
                </>
            )}
        </>
    );
};

export default FavoritesScreen;
