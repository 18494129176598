import React from "react";
import { Modal } from "react-bootstrap";
import ImageWithLoading from "../layout/ImageWithLoading";

interface Props {
    title: string | undefined;
    imageUrl: string | undefined;
    showImageModal: boolean;
    toggleImageModal: (x: boolean) => void;
}

const ImageModal: React.FC<Props> = ({
    title = "Product",
    imageUrl,
    showImageModal,
    toggleImageModal,
}) => {
    const handleClose = () => {
        toggleImageModal(false);
    };
    return (
        <Modal size="lg" centered show={showImageModal} onHide={handleClose}>
            <Modal.Header closeButton>
                <h6 className="my-0">{title}</h6>
            </Modal.Header>
            <Modal.Body className="m-auto">
                <ImageWithLoading src={imageUrl || ""} alt={title} fluid />
            </Modal.Body>
        </Modal>
    );
};

export default ImageModal;
