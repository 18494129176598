import axios from "axios";
import {
    addAlertHandler,
    API_URL,
    errorResponseHandler,
} from "../constants/helpers";
import {
    AsyncActionType,
    GeneralActionType,
    Product,
} from "../constants/types";
import {
    USER_LOGIN_REQUEST,
    USER_LOGIN_SUCCESS,
    USER_LOGIN_FAIL,
    USER_LOGOUT,
    USER_REGISTER_REQUEST,
    USER_REGISTER_SUCCESS,
    USER_REGISTER_FAIL,
    USER_DETAILS_REQUEST,
    USER_DETAILS_SUCCESS,
    USER_DETAILS_FAIL,
    USER_DETAILS_RESET,
    USER_LIST_REQUEST,
    USER_LIST_SUCCESS,
    USER_LIST_FAIL,
    USER_LIST_RESET,
    USER_UPDATE_ME_REQUEST,
    USER_UPDATE_ME_SUCCESS,
    USER_UPDATE_ME_FAIL,
    USER_GET_FAVORITES_SUCCESS,
    USER_GET_FAVORITES_REQUEST,
    USER_GET_FAVORITES_FAIL,
    USER_ADD_FAVORITE_REQUEST,
    USER_ADD_FAVORITE_SUCCESS,
    USER_ADD_FAVORITE_FAIL,
    USER_REMOVE_FAVORITE_REQUEST,
    USER_REMOVE_FAVORITE_SUCCESS,
    USER_REMOVE_FAVORITE_FAIL,
    USER_BLOCK_REQUEST,
    USER_BLOCK_SUCCESS,
    USER_BLOCK_FAIL,
} from "../constants/userConstants";
import { resetCart } from "./cartActions";
import { addMessage } from "./messageActions";
import {
    orderActionReset,
    resetOrderDetails,
    resetOrderListMy,
} from "./orderActions";

export const login: AsyncActionType =
    (identifier, password) => async (dispatch) => {
        try {
            dispatch({
                type: USER_LOGIN_REQUEST,
            });

            const config = {
                headers: {
                    "Content-Type": "application/json",
                },
            };
            const { data } = await axios.post(
                `${API_URL}auth/local`,
                { identifier, password },
                config
            );
            dispatch({
                type: USER_LOGIN_SUCCESS,
                payload: data,
            });

            localStorage.setItem("userInfo", JSON.stringify(data));
        } catch (error) {
            dispatch({
                type: USER_LOGIN_FAIL,
                payload: errorResponseHandler(error),
            });
            dispatch(addAlertHandler(error, "User Login Error"));
        }
    };

export const logout: GeneralActionType = () => (dispatch) => {
    localStorage.removeItem("userInfo");
    dispatch({
        type: USER_LOGOUT,
    });
    dispatch(resetUserDetails());
    dispatch(resetCart());
    dispatch(resetOrderDetails());
    dispatch(orderActionReset());
    dispatch(resetOrderListMy());
    dispatch(resetUserList());
};

export const register: AsyncActionType =
    (name, username, email, password) => async (dispatch) => {
        try {
            dispatch({
                type: USER_REGISTER_REQUEST,
            });

            const config = {
                headers: {
                    "Content-Type": "application/json",
                },
            };
            const { data } = await axios.post(
                `${API_URL}auth/local/register`,
                { name, username, email, password },
                config
            );
            dispatch({
                type: USER_REGISTER_SUCCESS,
                payload: data,
            });

            localStorage.setItem("userInfo", JSON.stringify(data));
        } catch (error) {
            dispatch({
                type: USER_REGISTER_FAIL,
                payload: errorResponseHandler(error),
            });
            dispatch(addAlertHandler(error, "User Register Error"));
        }
    };

export const getMyDetails: AsyncActionType =
    () => async (dispatch, getState) => {
        try {
            dispatch({
                type: USER_DETAILS_REQUEST,
            });

            const {
                userAuth: { userInfo },
            } = getState();

            const config = {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${userInfo?.jwt}`,
                },
            };
            const { data } = await axios.get(`${API_URL}users/me`, config);
            dispatch({
                type: USER_DETAILS_SUCCESS,
                payload: data,
            });
        } catch (error) {
            dispatch({
                type: USER_DETAILS_FAIL,
                payload: errorResponseHandler(error),
            });
            if (errorResponseHandler(error) === "Invalid token.") {
                dispatch(logout());
            }
            dispatch(addAlertHandler(error, "User Details Error"));
        }
    };

export const getUserDetails: AsyncActionType =
    (userId) => async (dispatch, getState) => {
        try {
            dispatch({
                type: USER_DETAILS_REQUEST,
            });

            const {
                userAuth: { userInfo },
            } = getState();

            const config = {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${userInfo?.jwt}`,
                },
            };
            const { data } = await axios.get(
                `${API_URL}users/${userId}`,
                config
            );
            dispatch({
                type: USER_DETAILS_SUCCESS,
                payload: data,
            });
        } catch (error) {
            dispatch({
                type: USER_DETAILS_FAIL,
                payload: errorResponseHandler(error),
            });
            dispatch(addAlertHandler(error, "User Details Error"));
        }
    };

export const getFavorites: AsyncActionType =
    () => async (dispatch, getState) => {
        try {
            dispatch({
                type: USER_GET_FAVORITES_REQUEST,
            });

            const {
                userAuth: { userInfo },
            } = getState();

            const config = {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${userInfo?.jwt}`,
                },
            };
            const { data } = await axios.get(
                `${API_URL}users/me/favorites`,
                config
            );
            dispatch({
                type: USER_GET_FAVORITES_SUCCESS,
                payload: data,
            });
        } catch (error) {
            dispatch({
                type: USER_GET_FAVORITES_FAIL,
                payload: errorResponseHandler(error),
            });
        }
    };

export const updateMe: AsyncActionType =
    (name, username, email, phone) => async (dispatch, getState) => {
        try {
            dispatch({
                type: USER_UPDATE_ME_REQUEST,
            });

            const {
                userAuth: { userInfo },
            } = getState();

            const config = {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${userInfo?.jwt}`,
                },
            };
            const { data } = await axios.put(
                `${API_URL}users/me`,
                { name, username, email, phone },
                config
            );
            dispatch({
                type: USER_UPDATE_ME_SUCCESS,
                payload: data,
            });

            localStorage.setItem(
                "userInfo",
                JSON.stringify({ jwt: userInfo?.jwt, user: data })
            );
        } catch (error) {
            dispatch({
                type: USER_UPDATE_ME_FAIL,
                payload: errorResponseHandler(error),
            });
            dispatch(addAlertHandler(error, "User Update Error"));
        }
    };

export const blockUser: AsyncActionType =
    (userId) => async (dispatch, getState) => {
        try {
            dispatch({
                type: USER_BLOCK_REQUEST,
            });

            const {
                userAuth: { userInfo },
            } = getState();

            const config = {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${userInfo?.jwt}`,
                },
            };

            if (userInfo?.user.id !== userId) {
                const { data } = await axios.put(
                    `${API_URL}users/${userId}`,
                    { blocked: true },
                    config
                );
                dispatch({
                    type: USER_BLOCK_SUCCESS,
                    payload: data,
                });
            } else {
                dispatch({
                    type: USER_BLOCK_FAIL,
                    payload: "Don't ban yourself!",
                });
                dispatch(
                    addMessage({
                        text: "DON'T BAN YOURSELF, DUMBASS!",
                        variant: "danger",
                        title: "NO",
                    })
                );
            }
        } catch (error) {
            dispatch({
                type: USER_BLOCK_FAIL,
                payload: errorResponseHandler(error),
            });
            dispatch(
                addMessage({
                    text: errorResponseHandler(error),
                    variant: "danger",
                    title: "User Block Error",
                })
            );
        }
    };

export const unblockUser: AsyncActionType =
    (userId) => async (dispatch, getState) => {
        try {
            dispatch({
                type: USER_BLOCK_REQUEST,
            });

            const {
                userAuth: { userInfo },
            } = getState();

            const config = {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${userInfo?.jwt}`,
                },
            };
            const { data } = await axios.put(
                `${API_URL}users/${userId}`,
                { blocked: false },
                config
            );
            dispatch({
                type: USER_BLOCK_SUCCESS,
                payload: data,
            });
        } catch (error) {
            dispatch({
                type: USER_BLOCK_FAIL,
                payload: errorResponseHandler(error),
            });
            dispatch(
                addMessage({
                    text: errorResponseHandler(error),
                    variant: "danger",
                    title: "User Block Error",
                })
            );
        }
    };

export const addFavorite: AsyncActionType =
    (favorites, productId) => async (dispatch, getState) => {
        try {
            dispatch({
                type: USER_ADD_FAVORITE_REQUEST,
            });

            const {
                userAuth: { userInfo },
            } = getState();

            const config = {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${userInfo?.jwt}`,
                },
            };
            const { data } = await axios.put(
                `${API_URL}users/me`,
                { favorites: [...favorites, productId] },
                config
            );
            dispatch({
                type: USER_ADD_FAVORITE_SUCCESS,
                payload: data,
            });

            localStorage.setItem(
                "userInfo",
                JSON.stringify({ jwt: userInfo?.jwt, user: data })
            );
        } catch (error) {
            dispatch({
                type: USER_ADD_FAVORITE_FAIL,
                payload: errorResponseHandler(error),
            });
            dispatch(
                addMessage({
                    text: errorResponseHandler(error),
                    variant: "danger",
                    title: "Add Favorite Error",
                })
            );
        }
    };

export const removeFavorite: AsyncActionType =
    (favorites, productId) => async (dispatch, getState) => {
        try {
            dispatch({
                type: USER_REMOVE_FAVORITE_REQUEST,
            });

            const {
                userAuth: { userInfo },
            } = getState();

            const config = {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${userInfo?.jwt}`,
                },
            };
            let newFavorites = favorites.filter(
                (el: Product) => el.id !== productId
            );
            const { data } = await axios.put(
                `${API_URL}users/me`,
                { favorites: newFavorites },
                config
            );
            dispatch({
                type: USER_REMOVE_FAVORITE_SUCCESS,
                payload: data,
            });

            localStorage.setItem(
                "userInfo",
                JSON.stringify({ jwt: userInfo?.jwt, user: data })
            );
        } catch (error) {
            dispatch({
                type: USER_REMOVE_FAVORITE_FAIL,
                payload: errorResponseHandler(error),
            });
            dispatch(
                addMessage({
                    text: errorResponseHandler(error),
                    variant: "danger",
                    title: "Remove Favorite Error",
                })
            );
        }
    };

export const resetUserDetails: GeneralActionType = () => (dispatch) => {
    dispatch({ type: USER_DETAILS_RESET });
};

export const getUserList: AsyncActionType =
    () => async (dispatch, getState) => {
        try {
            dispatch({
                type: USER_LIST_REQUEST,
            });

            const {
                userAuth: { userInfo },
            } = getState();

            const config = {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${userInfo?.jwt}`,
                },
            };
            const { data } = await axios.get(
                `${API_URL}users?_sort=updated_at:DESC`,
                config
            );
            dispatch({
                type: USER_LIST_SUCCESS,
                payload: data,
            });
        } catch (error) {
            dispatch({
                type: USER_LIST_FAIL,
                payload: errorResponseHandler(error),
            });
            dispatch(
                addMessage({
                    text: errorResponseHandler(error),
                    variant: "danger",
                    title: "User List Error",
                })
            );
        }
    };

export const resetUserList: GeneralActionType = () => (dispatch) => {
    dispatch({ type: USER_LIST_RESET });
};
