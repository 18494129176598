import React from "react";
import { Image } from "react-bootstrap";
import { GENERIC_IMAGE } from "../../constants/genericConstants";
import { Image as ImageType } from "../../constants/types";

interface Props {
    itemImages: ImageType[] | undefined | null;
}

const ImageThumbnailHandler: React.FC<Props> = ({ itemImages }) => {
    const returnImage = (itemImages: ImageType[]) => {
        if (itemImages != null) {
            if (itemImages.length > 0) {
                if (itemImages[0].formats?.thumbnail != null) {
                    return itemImages[0].formats.thumbnail.url;
                } else if (itemImages[0].url != null) {
                    return itemImages[0].url;
                }
            }
        }

        return GENERIC_IMAGE;
    };
    return (
        <Image
            src={returnImage(itemImages || [])}
            fluid
            rounded
            style={{ maxWidth: "60px" }}
        />
    );
};

export default ImageThumbnailHandler;
