import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Row } from "react-bootstrap";
import ProductCard from "../components/product/ProductCard";
import { getLatestProducts } from "../actions/productActions";
import Loader from "../components/Loader";
import { ProductListState } from "../reducers/productReducers";
import { RootState } from "../store";

const Home = () => {
    const dispatch = useDispatch();
    const productList: ProductListState = useSelector(
        (state: RootState) => state.productList
    );
    const { loading, error, products } = productList;

    useEffect(() => {
        dispatch(getLatestProducts());
    }, [dispatch]);

    return (
        <>
            {loading ? (
                <Loader />
            ) : error ? (
                <>
                    <h3 className="text-danger">Unable to load products</h3>
                </>
            ) : (
                <>
                    <h5 className="mt-2">Latest Products</h5>
                    <Row>
                        {products &&
                            products.map((product) => (
                                <Col
                                    key={product.id}
                                    sm={6}
                                    md={6}
                                    lg={4}
                                    xl={3}
                                    className="d-flex flex-column flex-sm-row"
                                >
                                    <ProductCard product={product} />
                                </Col>
                            ))}
                    </Row>
                </>
            )}
        </>
    );
};

export default Home;
