import axios from "axios";
import {
    CART_ADD_ITEM,
    CART_REMOVE_ITEM,
    CART_SAVE_SHIPPING_ADDRESS,
    CART_SAVE_PAYMENT_METHOD,
    CART_RESET,
} from "../constants/cartConstants";
import { API_URL, errorResponseHandler } from "../constants/helpers";
import { PRODUCT_DETAILS_FAIL } from "../constants/productConstants";
import {
    AsyncActionType,
    GeneralActionType,
    Product,
} from "../constants/types";
import { addMessage } from "./messageActions";

export const addToCart: AsyncActionType =
    (id: number, qty: number) => async (dispatch, getState) => {
        try {
            const { data } = await axios.get<Product>(
                `${API_URL}products/${id}`
            );
            dispatch({
                type: CART_ADD_ITEM,
                payload: {
                    product: data.id,
                    name: data.name,
                    image: data.images ? data.images[0].url : null,
                    price: data.price,
                    stock: data.stock,
                    qty,
                },
            });

            localStorage.setItem(
                "cartItems",
                JSON.stringify(getState().cart.cartItems)
            );
        } catch (error) {
            dispatch({
                type: PRODUCT_DETAILS_FAIL,
                payload: errorResponseHandler(error),
            });
            dispatch(
                addMessage({
                    text: errorResponseHandler(error),
                    variant: "danger",
                    title: "Get Product Error",
                })
            );
        }
    };

export const removeFromCart: GeneralActionType =
    (id: number) => (dispatch, getState) => {
        dispatch({
            type: CART_REMOVE_ITEM,
            payload: id,
        });

        localStorage.setItem(
            "cartItems",
            JSON.stringify(getState().cart.cartItems)
        );
    };

export const saveShippingAddress: GeneralActionType = (data) => (dispatch) => {
    dispatch({
        type: CART_SAVE_SHIPPING_ADDRESS,
        payload: data,
    });

    localStorage.setItem("shippingAddress", JSON.stringify(data));
};

export const savePaymentMethod: GeneralActionType = (data) => (dispatch) => {
    dispatch({
        type: CART_SAVE_PAYMENT_METHOD,
        payload: data,
    });

    localStorage.setItem("paymentMethod", JSON.stringify(data));
};

export const resetCart: GeneralActionType = () => (dispatch) => {
    dispatch({
        type: CART_RESET,
    });
    localStorage.removeItem("cartItems");
};
