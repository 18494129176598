import React, { useState } from "react";
import { LinkContainer } from "react-router-bootstrap";
import { Button, Table } from "react-bootstrap";
import FilteredComponent from "../layout/FilteredComponent";
import { Order } from "../../constants/types";

interface Props {
    orders: Order[];
}

const OrderAdminList: React.FC<Props> = ({ orders }) => {
    const [listItems, setListItems] = useState(orders);

    return (
        <>
            <FilteredComponent
                data={orders}
                setListItems={setListItems}
                searchOptions={[
                    { path: "user.name", title: "name" },
                    { path: "shippingAddress.address", title: "address" },
                ]}
            >
                <Table
                    striped
                    bordered
                    hover
                    responsive
                    className="table-sm text-center table-vcenter"
                >
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>NAME</th>
                            <th>ADDRESS</th>
                            <th>DATE</th>
                            <th>TOTAL</th>
                            <th>PAID</th>
                            <th>SENT</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {listItems != null &&
                            listItems.map((order) => (
                                <tr
                                    key={order.id}
                                    className={`${
                                        order.isCanceled && "text-secondary"
                                    }`}
                                >
                                    <td>{order.id}</td>
                                    <td>{order.user && order.user.name}</td>
                                    <td>
                                        {order.shippingAddress && (
                                            <span>
                                                {order.shippingAddress.address}{" "}
                                                {order.shippingAddress?.city}{" "}
                                                {
                                                    order.shippingAddress
                                                        ?.postalCode
                                                }{" "}
                                                {order.shippingAddress?.country}
                                            </span>
                                        )}
                                    </td>
                                    <td>
                                        {order.isCanceled ? (
                                            <span className="text-warning">
                                                Order Canceled
                                            </span>
                                        ) : (
                                            <>
                                                {order.created_at?.substring(
                                                    0,
                                                    10
                                                )}
                                            </>
                                        )}
                                    </td>
                                    <td>${order.totalPrice}</td>
                                    <td>
                                        {order.isPaid ? (
                                            <span className="text-success">
                                                {order.paidAt?.substring(0, 10)}
                                            </span>
                                        ) : order.paymentMethod !==
                                          "cashOnDelivery" ? (
                                            <i
                                                className="fas fa-times"
                                                style={{ color: "red" }}
                                            />
                                        ) : (
                                            <span className="text-info">
                                                Cash on Delivery
                                            </span>
                                        )}
                                    </td>
                                    <td>
                                        {order.isSent ? (
                                            <span className="text-success">
                                                {order.sentAt?.substring(0, 10)}
                                            </span>
                                        ) : (
                                            <i
                                                className="fas fa-times"
                                                style={{ color: "red" }}
                                            />
                                        )}
                                    </td>
                                    <td>
                                        <LinkContainer
                                            to={`/order/${order.id}`}
                                        >
                                            <Button
                                                variant="info"
                                                className="btn-sm"
                                            >
                                                <i className="fas fa-list"></i>
                                                <span className="d-block">
                                                    VIEW
                                                </span>
                                            </Button>
                                        </LinkContainer>
                                    </td>
                                </tr>
                            ))}
                    </tbody>
                </Table>
            </FilteredComponent>
        </>
    );
};

export default OrderAdminList;
