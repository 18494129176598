import axios from "axios";
import { API_URL, errorResponseHandler } from "../constants/helpers";
import {
    ORDER_ACTION_RESET,
    ORDER_CANCEL_FAIL,
    ORDER_CANCEL_REQUEST,
    ORDER_CANCEL_SUCCESS,
    ORDER_CREATE_FAIL,
    ORDER_CREATE_REQUEST,
    ORDER_CREATE_SUCCESS,
    ORDER_DETAILS_FAIL,
    ORDER_DETAILS_REQUEST,
    ORDER_DETAILS_RESET,
    ORDER_DETAILS_SUCCESS,
    ORDER_LIST_FAIL,
    ORDER_LIST_MY_FAIL,
    ORDER_LIST_MY_REQUEST,
    ORDER_LIST_MY_RESET,
    ORDER_LIST_MY_SUCCESS,
    ORDER_LIST_REQUEST,
    ORDER_LIST_RESET,
    ORDER_LIST_SUCCESS,
    ORDER_PAY_FAIL,
    ORDER_PAY_REQUEST,
    ORDER_PAY_SUCCESS,
    ORDER_SENT_FAIL,
    ORDER_SENT_REQUEST,
    ORDER_SENT_SUCCESS,
} from "../constants/orderConstants";
import { AsyncActionType, GeneralActionType } from "../constants/types";
import { addMessage } from "./messageActions";

export const createOrder: AsyncActionType =
    (order) => async (dispatch, getState) => {
        try {
            dispatch({
                type: ORDER_CREATE_REQUEST,
            });

            const {
                userAuth: { userInfo },
            } = getState();

            const config = {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${userInfo?.jwt}`,
                },
            };
            const { data } = await axios.post(
                `${API_URL}orders`,
                order,
                config
            );
            dispatch({
                type: ORDER_CREATE_SUCCESS,
                payload: data,
            });
        } catch (error) {
            dispatch({
                type: ORDER_CREATE_FAIL,
                payload: errorResponseHandler(error),
            });
            dispatch(
                addMessage({
                    text: errorResponseHandler(error),
                    variant: "danger",
                    title: "Order Create Error",
                })
            );
        }
    };

export const getOrderDetails: AsyncActionType =
    (id) => async (dispatch, getState) => {
        try {
            dispatch({
                type: ORDER_DETAILS_REQUEST,
            });

            const {
                userAuth: { userInfo },
            } = getState();

            const config = {
                headers: {
                    Authorization: `Bearer ${userInfo?.jwt}`,
                },
            };

            if (userInfo?.user?.role?.type === "admin") {
                const { data } = await axios.get(
                    `${API_URL}orders/${id}`,
                    config
                );
                dispatch({
                    type: ORDER_DETAILS_SUCCESS,
                    payload: data,
                });
            } else {
                const { data } = await axios.get(
                    `${API_URL}my-orders/${id}`,
                    config
                );
                dispatch({
                    type: ORDER_DETAILS_SUCCESS,
                    payload: data,
                });
            }
        } catch (error) {
            console.log(error);
            dispatch({
                type: ORDER_DETAILS_FAIL,
                payload: errorResponseHandler(error),
            });
            dispatch(
                addMessage({
                    text: errorResponseHandler(error),
                    variant: "danger",
                    title: "Order Details Error",
                })
            );
        }
    };

export const resetOrderDetails: GeneralActionType = () => (dispatch) => {
    dispatch({
        type: ORDER_DETAILS_RESET,
    });
    dispatch(orderActionReset);
};

export const payOrder: AsyncActionType =
    (orderId, paymentResult) => async (dispatch, getState) => {
        try {
            dispatch({
                type: ORDER_PAY_REQUEST,
            });

            const {
                userAuth: { userInfo },
            } = getState();

            const config = {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${userInfo?.jwt}`,
                },
            };
            const { data } = await axios.put(
                `${API_URL}my-orders/${orderId}/pay`,
                paymentResult,
                config
            );
            dispatch({
                type: ORDER_PAY_SUCCESS,
                payload: data,
            });
        } catch (error) {
            dispatch({
                type: ORDER_PAY_FAIL,
                payload: errorResponseHandler(error),
            });
            dispatch(
                addMessage({
                    text: errorResponseHandler(error),
                    variant: "danger",
                    title: "Order Pay Error",
                })
            );
        }
    };

export const orderActionReset: GeneralActionType = () => (dispatch) => {
    dispatch({
        type: ORDER_ACTION_RESET,
    });
};

export const cancelOrder: AsyncActionType =
    (orderId) => async (dispatch, getState) => {
        try {
            dispatch({
                type: ORDER_CANCEL_REQUEST,
            });

            const {
                userAuth: { userInfo },
            } = getState();

            const config = {
                headers: {
                    Authorization: `Bearer ${userInfo?.jwt}`,
                },
            };
            const { data } = await axios.put(
                `${API_URL}my-orders/${orderId}/cancel`,
                {},
                config
            );
            dispatch({
                type: ORDER_CANCEL_SUCCESS,
                payload: data,
            });
        } catch (error) {
            dispatch({
                type: ORDER_CANCEL_FAIL,
                payload: errorResponseHandler(error),
            });
            dispatch(
                addMessage({
                    text: errorResponseHandler(error),
                    variant: "danger",
                    title: "Order Cancel Error",
                })
            );
        }
    };

export const sentOrder: AsyncActionType =
    (orderId) => async (dispatch, getState) => {
        try {
            dispatch({
                type: ORDER_SENT_REQUEST,
            });

            const {
                userAuth: { userInfo },
            } = getState();

            const config = {
                headers: {
                    Authorization: `Bearer ${userInfo?.jwt}`,
                },
            };
            const { data } = await axios.put(
                `${API_URL}orders/${orderId}/sent`,
                {},
                config
            );
            dispatch({
                type: ORDER_SENT_SUCCESS,
                payload: data,
            });
        } catch (error) {
            dispatch({
                type: ORDER_SENT_FAIL,
                payload: errorResponseHandler(error),
            });
            dispatch(
                addMessage({
                    text: errorResponseHandler(error),
                    variant: "danger",
                    title: "Order Sent Error",
                })
            );
        }
    };

export const getOrderListMy: AsyncActionType =
    () => async (dispatch, getState) => {
        try {
            dispatch({
                type: ORDER_LIST_MY_REQUEST,
            });

            const {
                userAuth: { userInfo },
            } = getState();

            const config = {
                headers: {
                    Authorization: `Bearer ${userInfo?.jwt}`,
                },
            };
            const { data } = await axios.get(
                `${API_URL}my-orders?_sort=created_at:DESC`,
                config
            );
            dispatch({
                type: ORDER_LIST_MY_SUCCESS,
                payload: data,
            });
        } catch (error) {
            console.log(error);
            dispatch({
                type: ORDER_LIST_MY_FAIL,
                payload: errorResponseHandler(error),
            });
            dispatch(
                addMessage({
                    text: errorResponseHandler(error),
                    variant: "danger",
                    title: "Order List Error",
                })
            );
        }
    };

export const resetOrderListMy: GeneralActionType = () => (dispatch) => {
    dispatch({
        type: ORDER_LIST_MY_RESET,
    });
};

export const getOrderList: AsyncActionType =
    () => async (dispatch, getState) => {
        try {
            dispatch({
                type: ORDER_LIST_REQUEST,
            });

            const {
                userAuth: { userInfo },
            } = getState();

            const config = {
                headers: {
                    Authorization: `Bearer ${userInfo?.jwt}`,
                },
            };
            const { data } = await axios.get(
                `${API_URL}orders?_sort=updated_at:DESC`,
                config
            );
            dispatch({
                type: ORDER_LIST_SUCCESS,
                payload: data,
            });
        } catch (error) {
            console.log(error);
            dispatch({
                type: ORDER_LIST_FAIL,
                payload: errorResponseHandler(error),
            });
            dispatch(
                addMessage({
                    text: errorResponseHandler(error),
                    variant: "danger",
                    title: "Order List Error",
                })
            );
        }
    };

export const getUserOrderList: AsyncActionType =
    (userId) => async (dispatch, getState) => {
        try {
            dispatch({
                type: ORDER_LIST_REQUEST,
            });

            const {
                userAuth: { userInfo },
            } = getState();

            const config = {
                headers: {
                    Authorization: `Bearer ${userInfo?.jwt}`,
                },
            };
            const { data } = await axios.get(
                `${API_URL}orders?user=${userId}&_sort=updated_at:DESC`,
                config
            );
            dispatch({
                type: ORDER_LIST_SUCCESS,
                payload: data,
            });
        } catch (error) {
            console.log(error);
            dispatch({
                type: ORDER_LIST_FAIL,
                payload: errorResponseHandler(error),
            });
            dispatch(
                addMessage({
                    text: errorResponseHandler(error),
                    variant: "danger",
                    title: "Order List Error",
                })
            );
        }
    };

export const resetOrderList: GeneralActionType = () => (dispatch) => {
    dispatch({
        type: ORDER_LIST_RESET,
    });
};
