export const IMAGE_LIST_REQUEST = "IMAGE_LIST_REQUEST";
export const IMAGE_LIST_SUCCESS = "IMAGE_LIST_SUCCESS";
export const IMAGE_LIST_FAIL = "IMAGE_LIST_FAIL";

export const IMAGE_DETAILS_REQUEST = "IMAGE_DETAILS_REQUEST";
export const IMAGE_DETAILS_SUCCESS = "IMAGE_DETAILS_SUCCESS";
export const IMAGE_DETAILS_FAIL = "IMAGE_DETAILS_FAIL";
export const IMAGE_DETAILS_RESET = "IMAGE_DETAILS_RESET";

export const IMAGE_COUNT_REQUEST = "IMAGE_COUNT_REQUEST";
export const IMAGE_COUNT_SUCCESS = "IMAGE_COUNT_SUCCESS";
export const IMAGE_COUNT_FAIL = "IMAGE_COUNT_FAIL";
export const IMAGE_COUNT_RESET = "IMAGE_COUNT_RESET";

export const IMAGE_UPLOAD_REQUEST = "IMAGE_UPLOAD_REQUEST";
export const IMAGE_UPLOAD_SUCCESS = "IMAGE_UPLOAD_SUCCESS";
export const IMAGE_UPLOAD_FAIL = "IMAGE_UPLOAD_FAIL";

export const IMAGE_DELETE_REQUEST = "IMAGE_DELETE_REQUEST";
export const IMAGE_DELETE_SUCCESS = "IMAGE_DELETE_SUCCESS";
export const IMAGE_DELETE_FAIL = "IMAGE_DELETE_FAIL";

export const IMAGE_ACTION_RESET = "IMAGE_ACTION_RESET";
