import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "react-bootstrap";
import {
    UserActionsState,
    UserDetailsState,
} from "../../reducers/userReducers";
import {
    addFavorite,
    getFavorites,
    removeFavorite,
} from "../../actions/userActions";
import Loader from "../Loader";
import { RootState } from "../../store";

interface Props {
    productId: number;
}

const ProductFavoriteButton: React.FC<Props> = ({ productId }) => {
    const dispatch = useDispatch();

    const userDetails: UserDetailsState = useSelector(
        (state: RootState) => state.userDetails
    );
    const { loading, user } = userDetails;

    const userActions: UserActionsState = useSelector(
        (state: RootState) => state.userActions
    );
    const { loading: loadingAction } = userActions;

    useEffect(() => {
        dispatch(getFavorites());
    }, [dispatch]);

    const addFavoriteHandler = () => {
        dispatch(addFavorite(user?.favorites, productId));
    };

    const removeFavoriteHandler = () => {
        dispatch(removeFavorite(user?.favorites, productId));
    };

    const hasFavorite = (id: number) => {
        if (user?.favorites == null) {
            return null;
        } else {
            if (user?.favorites.filter((e) => e.id === id).length > 0) {
                return (
                    <Button
                        type="button"
                        variant="warning"
                        onClick={removeFavoriteHandler}
                    >
                        {loadingAction ? (
                            <Loader variant="small" />
                        ) : (
                            <>
                                <i className="fas fa-heart-broken" />
                            </>
                        )}
                        <br /> Unfav
                        <span className="d-md-none d-lg-inline">orite</span>
                    </Button>
                );
            } else {
                return (
                    <Button
                        type="button"
                        variant="success"
                        onClick={addFavoriteHandler}
                    >
                        {loadingAction ? (
                            <Loader variant="small" />
                        ) : (
                            <>
                                <i className="fas fa-heart" />
                            </>
                        )}
                        <br /> Favor
                        <span className="d-md-none d-lg-inline">ite</span>
                    </Button>
                );
            }
        }
    };

    return (
        <>{loading ? <Loader /> : user?.favorites && hasFavorite(productId)}</>
    );
};

export default ProductFavoriteButton;
