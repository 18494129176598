import React, { useState, useEffect } from "react";
import { Button, ButtonGroup, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { match, RouteComponentProps } from "react-router";
import {
    deleteMyReview,
    editMyReview,
    getReviewDetails,
    resetReviewAction,
} from "../../actions/reviewActions";
import GoBack from "../../components/layout/GoBack";
import Loader from "../../components/Loader";
import ReviewFields from "../../components/review/ReviewFields";
import { REVIEW_DELETE_REQUEST } from "../../constants/reviewConstants";
import {
    ReviewActionsState,
    ReviewDetailsState,
} from "../../reducers/reviewReducers";
import { UserAuthState } from "../../reducers/userReducers";
import { RootState } from "../../store";

interface Props extends RouteComponentProps {
    match: match<{ id: string }>;
}

const EditReviewScreen: React.FC<Props> = ({ match, history }) => {
    const reviewId = match.params.id;
    const dispatch = useDispatch();

    const [comment, setComment] = useState("");
    const [rating, setRating] = useState(0);

    const reviewDetails: ReviewDetailsState = useSelector(
        (state: RootState) => state.reviewDetails
    );
    const { loading, error, review } = reviewDetails;
    const reviewActions: ReviewActionsState = useSelector(
        (state: RootState) => state.reviewActions
    );
    const {
        success,
        loading: actionLoading,
        // error: actionError,
        actionType,
    } = reviewActions;

    const userAuth: UserAuthState = useSelector(
        (state: RootState) => state.userAuth
    );
    const { userInfo } = userAuth;

    useEffect(() => {
        if (success && actionType === REVIEW_DELETE_REQUEST) {
            dispatch(resetReviewAction());
            history.push("/reviews");
        } else {
            dispatch(getReviewDetails(reviewId));
        }
        //eslint-disable-next-line
    }, [dispatch, reviewId, success]);

    useEffect(() => {
        if (review) {
            setComment(review.comment || "");
            setRating(review.rating || 0);
        }

        if (
            typeof review?.user === "object" &&
            userInfo?.user.id !== review.user?.id
        )
            history.push("/");
        // eslint-disable-next-line
    }, [dispatch, review]);

    const submitHandler = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        dispatch(editMyReview(reviewId, { comment, rating }));
    };

    const deleteHandler = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
        e.preventDefault();
        dispatch(deleteMyReview(reviewId));
    };
    return (
        <>
            <GoBack link="/reviews" />
            <h4>Edit Review</h4>
            {loading || actionLoading ? (
                <Loader />
            ) : error ? (
                <h2 className="text-danger">{error}</h2>
            ) : (
                <Form onSubmit={submitHandler}>
                    <ReviewFields
                        comment={comment}
                        setComment={setComment}
                        rating={rating}
                        setRating={setRating}
                    />
                    <ButtonGroup>
                        <Button
                            type="submit"
                            variant="primary"
                            onSubmit={submitHandler}
                        >
                            Update
                        </Button>
                        <Button variant="danger" onClick={deleteHandler}>
                            Delete
                        </Button>
                    </ButtonGroup>
                </Form>
            )}
        </>
    );
};

export default EditReviewScreen;
