import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { match, RouteComponentProps } from "react-router";
import { getUserReviews } from "../../actions/reviewActions";
import { getUserDetails } from "../../actions/userActions";
import AdminOnly from "../../components/admin/AdminOnly";
import GoBack from "../../components/layout/GoBack";
import Loader from "../../components/Loader";
import ReviewList from "../../components/review/ReviewList";
import {
    ReviewActionsState,
    ReviewListState,
} from "../../reducers/reviewReducers";
import { UserDetailsState } from "../../reducers/userReducers";
import { RootState } from "../../store";

interface Props extends RouteComponentProps {
    match: match<{ id: string }>;
}

const UserReviewListScreen: React.FC<Props> = ({ match }) => {
    const userId = match.params.id;
    const dispatch = useDispatch();
    const reviewList: ReviewListState = useSelector(
        (state: RootState) => state.reviewList
    );
    const { loading, reviews } = reviewList;

    const userDetails: UserDetailsState = useSelector(
        (state: RootState) => state.userDetails
    );
    const { loading: loadingUser, user } = userDetails;

    const reviewActions: ReviewActionsState = useSelector(
        (state: RootState) => state.reviewActions
    );
    const { loading: actionLoading, success } = reviewActions;

    useEffect(() => {
        dispatch(getUserReviews(userId));
        dispatch(getUserDetails(userId));
        //eslint-disable-next-line
    }, [success]);
    return (
        <>
            <AdminOnly />
            <GoBack />
            <h4>
                Reviews{" "}
                {loadingUser ? (
                    <Loader variant="small" />
                ) : (
                    user && <span>of {user.name || user.username}</span>
                )}
            </h4>
            {loading || actionLoading ? (
                <Loader />
            ) : (
                <ReviewList
                    reviews={reviews || []}
                    loading={loading || false}
                />
            )}
        </>
    );
};

export default UserReviewListScreen;
