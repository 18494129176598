import React, { useEffect, useState } from "react";
import { Col, Container, Nav, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { listCategories } from "../../actions/categoryActions";
import Loader from "../Loader";
import { CategoryListState } from "../../reducers/categoryReducers";
import CategoryMenu from "./CategoryMenu";
import CategoryMenuMobile from "./CategoryMenuMobile";
import { RootState } from "../../store";

const Subheader: React.FC = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const categoryList: CategoryListState = useSelector(
        (state: RootState) => state.categoryList
    );
    const { loading, error, categories } = categoryList;

    const [showCategoryMenu, toggleCategoryMenu] = useState(
        location.pathname === "/"
    );
    useEffect(() => {
        toggleCategoryMenu(location.pathname === "/" && showCategoryMenu);
        // eslint-disable-next-line
    }, [location]);

    useEffect(() => {
        if (categories === null || categories?.length === 0) {
            dispatch(listCategories());
        }
    }, [dispatch, categories]);

    return (
        <Container>
            <Nav className="flex-column mt-2">
                <Row>
                    <Col md={4}>
                        {showCategoryMenu ? (
                            <>
                                <Nav.Link
                                    onClick={(
                                        e: React.MouseEvent<
                                            HTMLAnchorElement,
                                            MouseEvent
                                        >
                                    ) => toggleCategoryMenu(false)}
                                    style={{ minWidth: "236px" }}
                                >
                                    <h5 className="text-secondary text-center ">
                                        Categories{" "}
                                        <i className="fas fa-chevron-up" />
                                    </h5>
                                </Nav.Link>
                            </>
                        ) : (
                            <Nav.Link
                                onClick={(
                                    e: React.MouseEvent<
                                        HTMLAnchorElement,
                                        MouseEvent
                                    >
                                ) => toggleCategoryMenu(true)}
                                style={{ minWidth: "236px" }}
                            >
                                <h5 className="text-secondary text-center">
                                    Categories{" "}
                                    <i className="fas fa-chevron-down" />
                                </h5>
                            </Nav.Link>
                        )}
                    </Col>
                    <Col md={4} className=" d-none d-md-block">
                        <Nav.Link>
                            <h5 className="text-secondary text-center">
                                About Us
                            </h5>
                        </Nav.Link>
                    </Col>
                    <Col md={4} className=" d-none d-md-block">
                        <Nav.Link>
                            <h5 className="text-secondary text-center">
                                Contacts
                            </h5>
                        </Nav.Link>
                    </Col>
                </Row>
            </Nav>
            {loading ? (
                <Loader variant="small" />
            ) : error ? (
                <h6 className="text-danger">Unable to load categories</h6>
            ) : (
                <>
                    {categories && showCategoryMenu && (
                        <>
                            <CategoryMenu
                                categories={categories}
                                showCategoryMenu={showCategoryMenu}
                                toggleCategoryMenu={toggleCategoryMenu}
                            />
                            <CategoryMenuMobile
                                categories={categories}
                                showCategoryMenu={showCategoryMenu}
                            />
                        </>
                    )}
                </>
            )}
        </Container>
    );
};

export default Subheader;
