import React from "react";
import { Nav } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { Category } from "../../constants/types";

interface Props {
    activeCategory: Category;
}

const SubcategoryMenu: React.FC<Props> = ({ activeCategory }) => {
    return (
        <Nav className="flex-column bg-light h-100">
            {activeCategory.name && (
                <LinkContainer to={`/category/${activeCategory.id}`}>
                    <Nav.Link>
                        <h5 className="text-info">
                            All {activeCategory.name}{" "}
                            <i className="fas fa-chevron-down " />
                        </h5>
                    </Nav.Link>
                </LinkContainer>
            )}
            {activeCategory?.children?.map((subcategory) => (
                <LinkContainer
                    key={subcategory.id}
                    to={`/category/${subcategory.id}`}
                >
                    <Nav.Link key={subcategory.id}>
                        <h6>{subcategory.name}</h6>
                    </Nav.Link>
                </LinkContainer>
            ))}
        </Nav>
    );
};

export default SubcategoryMenu;
