import React from "react";
import { ListGroup } from "react-bootstrap";
import { Order } from "../../constants/types";

interface Props {
    order: Order;
}

const OrderCanceled: React.FC<Props> = ({ order }) => {
    return (
        <ListGroup.Item>
            <h4 className="text-danger">Order Canceled</h4>
            <h6 className="text-warning">Canceled on {order.canceledAt}</h6>
        </ListGroup.Item>
    );
};

export default OrderCanceled;
