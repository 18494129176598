import React from "react";
import { Form, InputGroup } from "react-bootstrap";

interface Props {
    itemsPerPage: number;
    setItemsPerPage: (itemsPerPage: number) => void;
    itemsPerPageOptions: number[];
}

const ItemsPerPageComponent: React.FC<Props> = ({
    itemsPerPage,
    setItemsPerPage,
    itemsPerPageOptions,
}) => {
    return (
        <InputGroup className="d-flex justify-content-end">
            <InputGroup.Prepend>
                <InputGroup.Text className="border-0 bg-info text-white">
                    Per Page
                </InputGroup.Text>
            </InputGroup.Prepend>
            <InputGroup.Append>
                <Form.Control
                    as="select"
                    className="border-0"
                    id="inlineFormCustomSelect"
                    custom
                    value={itemsPerPage}
                    onChange={(e) => setItemsPerPage(+e.target.value)}
                >
                    {itemsPerPageOptions.map((value) => (
                        <option
                            key={value}
                            className={`text-center ${
                                value === itemsPerPage
                                    ? "text-white bg-primary"
                                    : ""
                            }`}
                            value={value}
                        >
                            {value}
                        </option>
                    ))}
                </Form.Control>
            </InputGroup.Append>
        </InputGroup>
    );
};

export default ItemsPerPageComponent;
