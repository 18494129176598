import {
    ADD_MESSAGE,
    MessageType,
    REMOVE_ALL_MESSAGES,
    REMOVE_MESSAGE,
} from "../constants/messageConstants";
import { v4 } from "uuid";
import { GeneralActionType } from "../constants/types";

type NewMessageType = {
    text: string;
    title?: string | undefined;
    variant?: string | undefined;
};

export const addMessage: GeneralActionType =
    (message: NewMessageType) => (dispatch) => {
        let id = v4();
        let variant = "danger";
        let addedMessage: MessageType = { id, variant, ...message };
        dispatch({ type: ADD_MESSAGE, payload: addedMessage });
    };

export const removeMessage: GeneralActionType =
    (messageId: string) => (dispatch) => {
        dispatch({ type: REMOVE_MESSAGE, payload: messageId });
    };

export const removeAllMessages: GeneralActionType = () => (dispatch) => {
    dispatch({ type: REMOVE_ALL_MESSAGES });
};
