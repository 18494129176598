import axios from "axios";
import { API_URL, errorResponseHandler } from "../constants/helpers";
import {
    IMAGE_ACTION_RESET,
    IMAGE_COUNT_FAIL,
    IMAGE_COUNT_REQUEST,
    IMAGE_COUNT_RESET,
    IMAGE_COUNT_SUCCESS,
    IMAGE_DELETE_FAIL,
    IMAGE_DELETE_REQUEST,
    IMAGE_DELETE_SUCCESS,
    IMAGE_LIST_FAIL,
    IMAGE_LIST_REQUEST,
    IMAGE_LIST_SUCCESS,
    IMAGE_UPLOAD_FAIL,
    IMAGE_UPLOAD_REQUEST,
    IMAGE_UPLOAD_SUCCESS,
} from "../constants/imageConstants";
import { AsyncActionType, GeneralActionType } from "../constants/types";
import { addMessage } from "./messageActions";

export const countImages: AsyncActionType =
    () => async (dispatch, getState) => {
        try {
            dispatch({ type: IMAGE_COUNT_REQUEST });
            const {
                userAuth: { userInfo },
            } = getState();

            const config = {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${userInfo?.jwt}`,
                },
            };
            const { data } = await axios.get(
                `${API_URL}upload/files/count`,
                config
            );
            dispatch({
                type: IMAGE_COUNT_SUCCESS,
                payload: data,
            });
        } catch (error) {
            dispatch({
                type: IMAGE_COUNT_FAIL,
                payload: errorResponseHandler(error),
            });
            dispatch(
                addMessage({
                    text: errorResponseHandler(error),
                    variant: "danger",
                    title: "Image List Error",
                })
            );
        }
    };

export const resetImageCount: GeneralActionType = () => (dispatch) => {
    dispatch({ type: IMAGE_COUNT_RESET });
};

export const listImages: AsyncActionType =
    (start: number, limit: number) => async (dispatch, getState) => {
        try {
            dispatch({ type: IMAGE_LIST_REQUEST });
            const {
                userAuth: { userInfo },
            } = getState();

            const config = {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${userInfo?.jwt}`,
                },
            };
            const { data } = await axios.get(
                `${API_URL}upload/files?_start=${start}&_limit=${limit}&_sort=id:DESC`,
                config
            );
            dispatch({
                type: IMAGE_LIST_SUCCESS,
                payload: data,
            });
        } catch (error) {
            dispatch({
                type: IMAGE_LIST_FAIL,
                payload: errorResponseHandler(error),
            });
            dispatch(
                addMessage({
                    text: errorResponseHandler(error),
                    variant: "danger",
                    title: "Image List Error",
                })
            );
        }
    };

export const uploadImage: AsyncActionType =
    (file) => async (dispatch, getState) => {
        try {
            dispatch({ type: IMAGE_UPLOAD_REQUEST });
            const {
                userAuth: { userInfo },
            } = getState();

            const uploadData = new FormData();
            uploadData.append("files", file);

            const config = {
                headers: {
                    "Content-Type": "multipart/form-data",
                    Authorization: `Bearer ${userInfo?.jwt}`,
                },
            };
            const { data } = await axios.post(
                `${API_URL}upload/`,
                uploadData,
                config
            );
            dispatch({
                type: IMAGE_UPLOAD_SUCCESS,
                payload: data,
            });
            dispatch(resetImageCount());
            dispatch(resetImageAction());
        } catch (error) {
            dispatch({
                type: IMAGE_UPLOAD_FAIL,
                payload: errorResponseHandler(error),
            });
            dispatch(
                addMessage({
                    text: errorResponseHandler(error),
                    variant: "danger",
                    title: "Image Upload Error",
                })
            );
        }
    };

export const deleteImage: AsyncActionType =
    (id) => async (dispatch, getState) => {
        try {
            dispatch({ type: IMAGE_DELETE_REQUEST });
            const {
                userAuth: { userInfo },
            } = getState();

            const config = {
                headers: {
                    "Content-Type": "multipart/form-data",
                    Authorization: `Bearer ${userInfo?.jwt}`,
                },
            };
            await axios.delete(`${API_URL}upload/files/${id}`, config);
            dispatch({
                type: IMAGE_DELETE_SUCCESS,
            });
            dispatch(resetImageCount());
            dispatch(resetImageAction());
        } catch (error) {
            dispatch({
                type: IMAGE_DELETE_FAIL,
                payload: errorResponseHandler(error),
            });
            dispatch(
                addMessage({
                    text: errorResponseHandler(error),
                    variant: "danger",
                    title: "Image Delete Error",
                })
            );
        }
    };

export const resetImageAction: GeneralActionType = () => (dispatch) => {
    dispatch({
        type: IMAGE_ACTION_RESET,
    });
};
