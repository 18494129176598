import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Form } from "react-bootstrap";
import { RouteComponentProps } from "react-router";
import FormContainer from "../../components/FormContainer";
import CheckoutSteps from "../../components/order/CheckoutSteps";
import GoBack from "../../components/layout/GoBack";
import { saveShippingAddress } from "../../actions/cartActions";
import { CartState } from "../../reducers/cartReducers";
import { UserAuthState } from "../../reducers/userReducers";
import { RootState } from "../../store";

interface Props extends RouteComponentProps {}

const ShippingScreen: React.FC<Props> = ({ history }) => {
    const cart: CartState = useSelector((state: RootState) => state.cart);
    const { shippingAddress } = cart;
    const dispatch = useDispatch();

    const userAuth: UserAuthState = useSelector(
        (state: RootState) => state.userAuth
    );
    const user = userAuth.userInfo?.user;
    const [name, setName] = useState(user?.name);
    const [phone, setPhone] = useState(user?.phone);
    const [address, setAddress] = useState(shippingAddress.address);
    const [city, setCity] = useState(shippingAddress.city);
    const [postalCode, setPostalCode] = useState(shippingAddress.postalCode);
    const [country, setCountry] = useState(shippingAddress.country);
    const [comment, setComment] = useState(shippingAddress.comment);

    const submitHandler = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        dispatch(
            saveShippingAddress({
                name,
                phone,
                address,
                city,
                postalCode,
                country,
                comment,
            })
        );
        history.push("/payment");
    };
    return (
        <>
            <GoBack />
            <CheckoutSteps step1 step2 />
            <FormContainer>
                <h1>Shipping</h1>
                <Form onSubmit={submitHandler}>
                    <Form.Group controlId="name">
                        <Form.Label>Name</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Enter Name"
                            value={name}
                            required
                            onChange={(e) => setName(e.target.value)}
                        ></Form.Control>
                    </Form.Group>
                    <Form.Group controlId="phone">
                        <Form.Label>Phone</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Enter Phone Number"
                            value={phone}
                            onChange={(e) => setPhone(e.target.value)}
                        ></Form.Control>
                    </Form.Group>
                    <Form.Group controlId="address">
                        <Form.Label>Address</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Enter Address"
                            value={address}
                            required
                            onChange={(e) => setAddress(e.target.value)}
                        ></Form.Control>
                    </Form.Group>
                    <Form.Group controlId="city">
                        <Form.Label>City</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Enter City"
                            value={city}
                            required
                            onChange={(e) => setCity(e.target.value)}
                        ></Form.Control>
                    </Form.Group>
                    <Form.Group controlId="postalCode">
                        <Form.Label>PostalCode</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Enter Postal Code"
                            value={postalCode}
                            onChange={(e) => setPostalCode(e.target.value)}
                        ></Form.Control>
                    </Form.Group>
                    <Form.Group controlId="country">
                        <Form.Label>Country</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Enter Country"
                            value={country}
                            required
                            onChange={(e) => setCountry(e.target.value)}
                        ></Form.Control>
                    </Form.Group>
                    <Form.Group controlId="comment">
                        <Form.Label>Comment</Form.Label>
                        <Form.Control
                            as="textarea"
                            placeholder="Optional comment"
                            value={comment}
                            onChange={(e) => setComment(e.target.value)}
                            rows={3}
                            maxLength={250}
                        ></Form.Control>
                    </Form.Group>
                    <Button type="submit" variant="primary">
                        Continue
                    </Button>
                </Form>
            </FormContainer>
        </>
    );
};

export default ShippingScreen;
