import {
    CATEGORY_ACTION_RESET,
    CATEGORY_CREATE_FAIL,
    CATEGORY_CREATE_REQUEST,
    CATEGORY_CREATE_SUCCESS,
    CATEGORY_DELETE_FAIL,
    CATEGORY_DELETE_REQUEST,
    CATEGORY_DELETE_SUCCESS,
    CATEGORY_DETAILS_FAIL,
    CATEGORY_DETAILS_REQUEST,
    CATEGORY_DETAILS_RESET,
    CATEGORY_DETAILS_SUCCESS,
    CATEGORY_LIST_FAIL,
    CATEGORY_LIST_REQUEST,
    CATEGORY_LIST_SUCCESS,
    CATEGORY_UPDATE_FAIL,
    CATEGORY_UPDATE_REQUEST,
    CATEGORY_UPDATE_SUCCESS,
} from "../constants/categoryContants";
import { Category, GeneralStateIF, GenericAction } from "../constants/types";

const categoryActionInitialState = {};

export interface CategoryActionState extends GeneralStateIF {
    actionType?: string;
}

export const categoryActionReducer = (
    state: CategoryActionState = categoryActionInitialState,
    action: GenericAction
): CategoryActionState => {
    switch (action.type) {
        case CATEGORY_UPDATE_REQUEST:
            return { loading: true, actionType: CATEGORY_UPDATE_REQUEST };
        case CATEGORY_DELETE_REQUEST:
            return { loading: true, actionType: CATEGORY_DELETE_REQUEST };
        case CATEGORY_CREATE_REQUEST:
            return { loading: true, actionType: CATEGORY_CREATE_REQUEST };
        case CATEGORY_UPDATE_SUCCESS:
        case CATEGORY_DELETE_SUCCESS:
        case CATEGORY_CREATE_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case CATEGORY_UPDATE_FAIL:
        case CATEGORY_DELETE_FAIL:
        case CATEGORY_CREATE_FAIL:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case CATEGORY_ACTION_RESET:
            return categoryActionInitialState;
        default:
            return state;
    }
};

const categoryListInitialState = {
    categories: [],
};

export interface CategoryListState extends GeneralStateIF {
    categories?: Category[];
}

export const categoryListReducer = (
    state: CategoryListState = categoryListInitialState,
    action: GenericAction
): CategoryListState => {
    switch (action.type) {
        case CATEGORY_LIST_REQUEST:
            return { loading: true, ...state };
        case CATEGORY_LIST_SUCCESS:
            return {
                loading: false,
                categories: action.payload,
            };
        case CATEGORY_LIST_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
};

const categoryDetailsInitialState = {
    category: { products: [], children: [], parent: null },
};

export interface CategoryDetailsState extends GeneralStateIF {
    category?: Category;
}

export const categoryDetailsReducer = (
    state: CategoryDetailsState = categoryDetailsInitialState,
    action: GenericAction
): CategoryDetailsState => {
    switch (action.type) {
        case CATEGORY_DETAILS_REQUEST:
            return {
                loading: true,
                ...state,
            };
        case CATEGORY_DETAILS_SUCCESS:
        case CATEGORY_UPDATE_SUCCESS:
        case CATEGORY_CREATE_SUCCESS:
            return {
                loading: false,
                category: action.payload,
            };
        case CATEGORY_DETAILS_FAIL:
            return { loading: false, error: action.payload };
        case CATEGORY_DETAILS_RESET:
        case CATEGORY_DELETE_SUCCESS:
            return categoryDetailsInitialState;
        default:
            return state;
    }
};
