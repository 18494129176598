import React, { useEffect, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { LinkContainer } from "react-router-bootstrap";
import { match, RouteComponentProps } from "react-router-dom";
import { countProductList, getProductList } from "../../actions/productActions";
import AdminOnly from "../../components/admin/AdminOnly";
import GoBack from "../../components/layout/GoBack";
import Loader from "../../components/Loader";
import {
    ProductActionsState,
    ProductListState,
} from "../../reducers/productReducers";
import { RootState } from "../../store";
import ContentHeader from "../../components/layout/ContentHeader";
import ContentSidebar from "../../components/layout/ContentSidebar";
import { isMobile } from "react-device-detect";
import { FilteringState } from "../../reducers/filteringReducers";
import ProductFilteringController from "../../components/product/ProductFIlteringController";
import ContentMain from "../../components/layout/ContentMain";
import SidebarToggle from "../../components/layout/SidebarToggle";
import ProductPagination from "../../components/product/ProductPagination";
import ProductAdminTable from "../../components/product/ProductAdminTable";
import ContentTitle from "../../components/layout/ContentTitle";
import Announcer from "../../components/layout/Announcer";
import ContentFooter from "../../components/layout/ContentFooter";

interface Props extends RouteComponentProps {
    match: match<{ params?: string }>;
}

const ProductListScreen: React.FC<Props> = ({ match }) => {
    const dispatch = useDispatch();

    const [showFilterMenu, toggleFilterMenu] = useState(!isMobile);
    const filtering: FilteringState = useSelector(
        (state: RootState) => state.filtering
    );
    const { filters } = filtering;

    const productList: ProductListState = useSelector(
        (state: RootState) => state.productList
    );
    const { loading, error, products } = productList;
    const productActions: ProductActionsState = useSelector(
        (state: RootState) => state.productActions
    );
    const { loading: loadingAction, success } = productActions;

    const [currentPage, setCurrentPage] = useState(1);

    useEffect(() => {
        dispatch(countProductList(match.params.params));
        dispatch(getProductList(match.params.params, currentPage));
        // eslint-disable-next-line
    }, [success, filters._limit, filters._sort, match]);

    useEffect(() => {
        dispatch(getProductList(match.params.params, currentPage));
        // eslint-disable-next-line
    }, [currentPage]);

    return (
        <>
            <AdminOnly />
            <GoBack />
            <ContentHeader>
                <Col>
                    <ContentTitle text="Products" />
                </Col>
                <Col className="text-right">
                    <LinkContainer to="/admin/product/create">
                        <Button variant="primary">
                            <i className="fas fa-plus" /> Create Product
                        </Button>
                    </LinkContainer>
                </Col>
            </ContentHeader>
            <ContentHeader>
                <SidebarToggle
                    showSidebar={showFilterMenu}
                    toggleSidebar={toggleFilterMenu}
                />
                <ProductPagination
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    hideOnMobile
                />
            </ContentHeader>
            <Row>
                <ContentSidebar showSideBar={showFilterMenu}>
                    <ProductFilteringController
                        match={match}
                        showCategorySelector
                        perPageOptions={[24, 50, 75, 100]}
                    />
                </ContentSidebar>
                <ContentMain showSideBar={showFilterMenu}>
                    <Row className="d-block d-md-none mb-2">
                        <ProductPagination
                            currentPage={currentPage}
                            setCurrentPage={setCurrentPage}
                        />
                    </Row>
                    {loading || loadingAction ? (
                        <Loader />
                    ) : error ? (
                        <Announcer text="Product List Error" />
                    ) : (
                        <>
                            <ProductAdminTable products={products || []} />
                        </>
                    )}
                </ContentMain>
            </Row>
            <ContentFooter>
                <ProductPagination
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                />
            </ContentFooter>
        </>
    );
};

export default ProductListScreen;
