import React, { useState, useEffect } from "react";
import { Button, ButtonGroup, Col, Form, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { RouteComponentProps } from "react-router";
import {
    createProduct,
    resetProductActions,
} from "../../actions/productActions";
import AdminOnly from "../../components/admin/AdminOnly";
import GoBack from "../../components/layout/GoBack";
import Loader from "../../components/Loader";
import RichTextEditor from "../../components/product/RichTextEditor";
import DOMPurify from "dompurify";
import { PRODUCT_CREATE_REQUEST } from "../../constants/productConstants";
import {
    ProductActionsState,
    ProductDetailsState,
} from "../../reducers/productReducers";
import { Image } from "../../constants/types";
import ProductEditImages from "../../components/images/ProductEditImages";
import ProductFieldForm from "../../components/product/ProductFieldForm";
import { CategoryListState } from "../../reducers/categoryReducers";
import { RootState } from "../../store";

interface Props extends RouteComponentProps {}

const ProductCreateScreen: React.FC<Props> = ({ history }) => {
    const dispatch = useDispatch();
    const productDetails: ProductDetailsState = useSelector(
        (state: RootState) => state.productDetails
    );
    const { loading, error, product } = productDetails;
    const productActions: ProductActionsState = useSelector(
        (state: RootState) => state.productActions
    );
    const { success, loading: actionLoading, actionType } = productActions;
    const categoryList: CategoryListState = useSelector(
        (state: RootState) => state.categoryList
    );
    const { categories } = categoryList;

    const [name, setName] = useState("");
    const [price, setPrice] = useState(0);
    const [brand, setBrand] = useState("");
    const [category, setCategory] = useState(0);
    const [code, setCode] = useState("");
    const [countInStock, setCountInStock] = useState(0);
    const [description, setDescription] = useState("");
    const [images, setImages] = useState<Array<Image> | null>(null);

    useEffect(() => {
        dispatch(resetProductActions);
    }, [dispatch]);

    useEffect(() => {
        if (success && actionType === PRODUCT_CREATE_REQUEST) {
            history.push(`/product/${product?.id}`);
            dispatch(resetProductActions());
        }

        // eslint-disable-next-line
    }, [dispatch, product, success, actionType]);

    const submitHandler = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        dispatch(
            createProduct({
                name,
                price,
                brand,
                category: category !== 0 ? category : null,
                code,
                stock: countInStock,
                description: DOMPurify.sanitize(description),
                images,
            })
        );
    };
    return (
        <>
            <AdminOnly />
            <GoBack link="/admin/productlist" />
            <h4>Create Product</h4>
            {loading || actionLoading ? (
                <Loader />
            ) : error ? (
                <h2 className="text-danger">{error}</h2>
            ) : (
                <Form onSubmit={submitHandler}>
                    <Row>
                        <Col md={6} lg={7}>
                            <ProductFieldForm
                                name={name}
                                setName={setName}
                                price={price}
                                setPrice={setPrice}
                                code={code}
                                setCode={setCode}
                                brand={brand}
                                setBrand={setBrand}
                                countInStock={countInStock}
                                setCountInStock={setCountInStock}
                                categories={categories || []}
                                setCategory={setCategory}
                                categoryId={
                                    (typeof product?.category === "object" &&
                                        product.category.id) ||
                                    0
                                }
                            />
                        </Col>
                        <Col md={6} lg={5} className="mt-3 mt-md-0">
                            {product?.images != null && (
                                <ProductEditImages
                                    title={product.name}
                                    images={images}
                                    setImages={setImages}
                                />
                            )}
                        </Col>
                    </Row>
                    <Row>
                        <Col className="mt-3 mt-md-0">
                            <Form.Group controlId="description">
                                <Form.Label>Description</Form.Label>
                                <RichTextEditor
                                    description={description}
                                    setDescription={setDescription}
                                />
                            </Form.Group>
                            <ButtonGroup>
                                <Button
                                    type="submit"
                                    variant="primary"
                                    onSubmit={submitHandler}
                                >
                                    Create
                                </Button>
                            </ButtonGroup>
                        </Col>
                    </Row>
                </Form>
            )}
        </>
    );
};

export default ProductCreateScreen;
