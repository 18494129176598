import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { match, RouteComponentProps } from "react-router";
import { getOrderList, getUserOrderList } from "../../actions/orderActions";
import AdminOnly from "../../components/admin/AdminOnly";
import GoBack from "../../components/layout/GoBack";
import Loader from "../../components/Loader";
import OrderAdminList from "../../components/order/OrderAdminList";
import { OrderListState } from "../../reducers/orderReducers";
import { RootState } from "../../store";

interface Props extends RouteComponentProps {
    match: match<{ id: string }>;
}

const OrderListScreen: React.FC<Props> = ({ match }) => {
    const dispatch = useDispatch();

    const orderList: OrderListState = useSelector(
        (state: RootState) => state.orderList
    );
    const { loading, error, orders } = orderList;

    useEffect(() => {
        if (match.params.id != null) {
            dispatch(getUserOrderList(match.params.id));
        } else {
            dispatch(getOrderList());
        }
    }, [dispatch, match]);

    return (
        <>
            <AdminOnly />
            <GoBack link={match.params.id != null ? "/admin/userlist" : "/"} />
            <h4>Orders</h4>
            {loading ? (
                <Loader />
            ) : error ? (
                <h2 className="text-danger">{error}</h2>
            ) : (
                <>
                    {orders ? (
                        <OrderAdminList orders={orders} />
                    ) : (
                        <h5 className="text-info text-center">No Orders</h5>
                    )}
                </>
            )}
        </>
    );
};

export default OrderListScreen;
