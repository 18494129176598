import React from "react";
import { Col, Container, Row } from "react-bootstrap";

const Footer = () => {
    return (
        <footer className="footer-copyright d-flex text-center my-3 py-3 bg-dark">
            <Container>
                <Row>
                    <Col className="text-center text-light py-3">
                        Copyright &copy;
                        <a
                            href="https://b-raychev.com/"
                            rel="noreferrer"
                            target="_blank"
                            className="text-info"
                        >
                            Borislav Raychev
                        </a>{" "}
                        2021
                    </Col>
                </Row>
            </Container>
        </footer>
    );
};

export default Footer;
