import React from "react";
import AdminOnly from "../../components/admin/AdminOnly";
import GoBack from "../../components/layout/GoBack";
import ImageGallery from "../../components/images/ImageGallery";

const ImageListScreen = () => {
    return (
        <>
            <AdminOnly />
            <GoBack />
            <ImageGallery />
        </>
    );
};

export default ImageListScreen;
